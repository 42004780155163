import { Component, Input } from '@angular/core';
import { FormArray } from '@angular/forms';
import { LoadingComponent } from '@app/admin/components/loading/loading.component';
import { Const } from '@const/Const';
import { BaseFormDialog1 } from '@dialogs/base-form-dlg1';
import { DialogService } from '@dialogs/dialog.service';
import { DateUtil } from '@services/date-utils';
import { Utils } from '@services/utils';
import { ZipcodeService } from '@services/zipcode.service';
import { ulid } from 'ulid';
import { ZipcodeSelectorValue } from '@app/admin/components/common/zipcode-selector';

@Component({
  selector: '[clone-from-route]',
  templateUrl: './view.html',
  styleUrls: ['./style.scss']
})
export class CloneRouteDialog extends BaseFormDialog1 {

  public canAddMultiStop = false;
  public isLoading: boolean = false;
  @Input() onSubmitSucceeded: (resp) => void;
  @Input() routeId: any;
  zipcodeService;
  items = [];
  public vehicleTypeValue;
  public shouldSetHoursDefaul: boolean = true;
  public isCloningFromLoad = false;
  private jobCode: string = '';
  loadingRef;

  constructor() {
    super();
    this.zipcodeService = new ZipcodeService(this.api)
  }

  protected formGroupDeclaration: FormGroupDeclaration = {
    requiredVehicle: { label: 'Vehicle', notAcceptEmpty: true, placeHolder: 'Select' },
    addressType: { label: 'Address Type', initialValue: 'fullAddress'},
    pickInfos: { label: 'Pickup Informations', type: 'formArray', initialValue: [{}], childItem: {
      zipcode: { label: 'Pickup zipcode'},
      address: {label: 'Address' },
      rangeDate: { label: 'Pickup date'},
    }},
    dropInfos: { label: 'Delivery Informations', type: 'formArray', initialValue: [{}], childItem: {
      zipcode: { label: 'Pickup zipcode'},
      address: {label: 'Address' },
      rangeDate: { label: 'Pickup date'},
    }},
    stops: { label: 'Stop Informations', type: 'formArray', initialValue: [], childItem: {
      type: {label: 'Type' },
      address: {label: 'Address' },
      rangeDate: { label: 'Pickup date'},
    }},
  }

  public listAddressTypes = [
    { value: 'zipcode', label: 'Zipcode' },
    { value: 'fullAddress', label: 'Full Address' },
  ]

  ngOnInit() {
    super.ngOnInit();
    this.getRouteDetail();
  }

  onBtnClose() {
    this.closeDialog();
  }

  onVehicleTypeChange(e) {
    this.setItemValue('requiredVehicle', e);
  }

  get isFullAdrressSelected() {
    return this.getItemValue('addressType') === 'fullAddress';
  }

  getLabelAddressType(groupKey: 'pickInfos'|'dropInfos') {
    let addressType = this.isFullAdrressSelected ? 'Address' : 'zipcode';
    let taskType = groupKey === 'pickInfos' ? 'Pickup' : 'Delivery';
    return `${taskType} ${addressType}`;
  }


  getStopAddress(index: number) {
    const addr = this.getItemValue(`stops[${index}].address`);
    const type = this.getItemValue(`stops[${index}].type`);
    return `${type} at ${this.getAddressText(addr)}`;
  }

  protected getFormData_JSON(isCreateNew: boolean): object {
    let json: any = super.getFormData_JSON(true); // always get full data
    let dataCustom: any = {};
    dataCustom.requiredVehicle = json.requiredVehicle;
    dataCustom.deliveryInfos = [];
    if (this.isCloningFromLoad) {
      let infos = json['stops'];
      for (let item of infos || []){
        let deliveryInfo: any = {};
        deliveryInfo.type = item.type;
        deliveryInfo.addr = item.address;

        if (!item.rangeDate) {
          deliveryInfo.windows = [{ from: null, to: null }];
        } else if (Utils.isArrayNotEmpty(item.rangeDate)) {
          let fromPickDate = item.rangeDate[0];
          let toPickDate = item.rangeDate[item.rangeDate.length - 1];
          let timezone = deliveryInfo.addr?.metadata?.timeZoneStandard;
          fromPickDate = DateUtil.convertLocalTime(fromPickDate, timezone)?.toISOString();
          toPickDate = DateUtil.convertLocalTime(toPickDate, timezone)?.toISOString();
          deliveryInfo.windows = [{
            from: fromPickDate,
            to: toPickDate
          }]
        }
        dataCustom.deliveryInfos.push(deliveryInfo);
      }
    }
    
    return dataCustom;
  }

  private get canSubmitForm(): boolean {
    if (!this.jobCode) {
      this.showErr('Please enter a Route ID');
      return false;
    }
    for (let item of this.items) {
      if (!item.weight || !item.height || !item.length || !item.weight) {
        this.showErr('Shipping Item is required.')
        return false;
      }
    }
    return true;
  }

  onBtnCreateLoad() {
    if (!this.canSubmitForm) return false;
    const payload: any = this.getFormData_JSON(true);
    if (this.items?.length) {
      payload.items = this.items;
    }
    this.startProgress();
    this.loadingRef = DialogService.openFormDialog1(LoadingComponent, {});
    this.api.POST(Const.APIV2(`${Const.APIURI_JOBS}/manual-load`), payload).subscribe(
      (resp) => {
        this.stopProgress();
        this.showSuccess('Load has been created successfully.');
        this.loadingRef?.close();
        this.onSubmitRequestSucceeded(resp);
      },
      (err) => {
        this.showErr(err);
        this.stopProgress();
        this.loadingRef?.close();
      }
    );
  }

  onSubmitRequestSucceeded(resp) {
    this.onSubmitSucceeded(resp);
    this.closeDialog();
  }

  onAddNewItem(type) {
    let item = {
        id: ulid(),
        packaging: type,
        quantity: 1
    }
    if (type === 'Pallet') {
        item['length'] = 40
        item['width'] = 48
        item['height'] = 50
    }
    this.items.push(item)
  }

  onDeleteItem(id) {
    this.items = this.items.filter(it => it.id !== id)
  }

  bindingDataRouteToForm(resp) {
    if (!resp?.data?.id) return;
    // fixme  tam thoi
    const { code, requiredVehicle, stops, metadata } = resp.data;
    // if (stops.length && stops.length != 2) {
    //   this.showErr('Not support number of stops more than 2');
    //   return
    // }
    this.jobCode = code;
    if (requiredVehicle?.code && requiredVehicle?.name) {
      this.vehicleTypeValue = requiredVehicle;
      this.onVehicleTypeChange(this.vehicleTypeValue);
    }
    this.setItemValue('addressType', 'fullAddress');

    this.setItemValue('stops', []);
    for (let i=0; i< stops.length; i++) {
      this.addItemToFormArray('stops');
      this.formInput.get('stops').updateValueAndValidity();
      const stop = stops[i];
      const addr = stop?.info?.addr;
      this.setItemValue(`stops[${i}].type`, stop.type);
      if (addr) {
        this.setItemValue(`stops[${i}].address`, addr);
        const timeWindow = this.getAppointment(stop.info);
        const stopTimeZone = addr.metadata?.timeZoneStandard;
        if (timeWindow?.from && stopTimeZone) {
          const fromTime = DateUtil.convertLocalTime2(timeWindow.from, stopTimeZone);
          const toTime = DateUtil.convertLocalTime2(timeWindow.to, stopTimeZone);
          if (fromTime && toTime) {
            this.setItemValue(`stops[${i}].rangeDate`, [fromTime, toTime]);
          }
        }
      }
    }
    if (metadata?.shipmentItems?.length) {
      let items: any[] = [];
      for (let item of metadata.shipmentItems) {
        if (item.qtyUnit != "Pallet") continue;
        items.push({
          id: ulid(),
          packaging: "Pallet",
          quantity: item.qty ?? 1,
          length: item.length ?? 40,
          width: item.width ?? 48,
          height: item.height ?? 50,
          weight: item.weightPerUnit,
        });
      }
      this.items = items;
    }
    this.isCloningFromLoad = true;
  }

  private getAppointment(info) {
    const appointment = info.appointmentInfo;
    if (appointment?.from) return appointment

    const timeWindow = info?.windows?.[0];
    if (timeWindow?.from) return timeWindow;
  }

  private getRouteDetail() {
    const url = `${Const.APIV2(Const.APIURI_JOBS)}/get-detail-by-code/${this.routeId}`;
    this.isLoading = true;
    this.api.GET(url).subscribe((resp) => {
      this.isLoading = false;
      this.bindingDataRouteToForm(resp);
    }, 
    (err) => {
      this.isLoading = false;
      this.showErr(err);
    })
  }

}