import { Component, Input, OnInit } from "@angular/core";
import { Const } from "@const/Const";
import { UIHelper } from "@services/UIHelper";
import { ApiService } from "@services/api.service";

interface Data {
  routeId: string,
  distance: string,
  tempRanges:string,
  equipment: string,
  productType: string,
  classification: string,
  stops: {
    type: string,
    address: string,
    time: string
  }[]
}

@Component({
  selector: '[basic-route-info]',
  templateUrl: './view.html',
  styleUrls: ['./style.scss']
})
export class BasicRouteInfo implements OnInit {
  @Input() jobId: string;
  data: Data;
  isLoading = false;

  ngOnInit(): void {
    this.getData();
  }

  private getData() {
    this.isLoading = true;
    ApiService.instance.GET(Const.APIV2(`jobs/${this.jobId}/basic-route-info?dataOnly=1`)).subscribe(
      resp => {
        this.data = resp.data;
        this.isLoading = false;
      }, err => {
        UIHelper.showErr(err);
        this.isLoading = false;
      }
    );
  }

}