<div class="filter-group">
  <nz-select nzAllowClear nzPlaceHolder="Saved Filters" nzShowSearch class="filter-select full-w" [nzSuffixIcon]="filterIcon" [ngModel]="selectedId" (ngModelChange)="onSelect($event)">
    <nz-option *ngFor="let filter of listFilters" nzCustomContent [nzValue]="filter.id" [nzLabel]="filter.data?.name">
      <i nz-icon nzType="pushpin" nzTheme="fill" *ngIf="isDefault(filter.id)"></i>
      {{ filter.data?.name ?? "\<no-name\>"}}
    </nz-option>
  </nz-select>
  <ng-template #filterIcon>
    <i nz-icon nzType="filter" nzTheme="fill"></i>
  </ng-template>
  <!-- <button nz-button nzType="text" title="add" (click)="onBtnAdd()">
    <i nz-icon nzType="plus"></i>
  </button> -->
  <ng-container *ngIf="options?.length > 0">
    <button nz-button nzType="text" title="Filter by Walmart's clients" nzDanger class="left5" nz-dropdown [nzDropdownMenu]="menu" nzPlacement="bottomRight">
      <i nz-icon nzType="filter" nzTheme="twotone"></i>
    </button>
    <nz-dropdown-menu #menu="nzDropdownMenu">
      <ul nz-menu>
        <li *ngFor="let option of options" [nzSelected]="option.value === selectedOptionId" nz-menu-item (click)="onSelectOption(option.value)">{{ option.label || option.value }}</li>
      </ul>
    </nz-dropdown-menu>
  </ng-container>
  <button nz-button nzType="text" title="edit" [disabled]="!canEdit()" (click)="onBtnEdit()" class="left5">
    <i nz-icon nzType="edit"></i>
  </button>
  <button nz-button nzType="text" title="delete" [disabled]="!canEdit()" nzDanger (click)="onBtnDelete()" class="left5">
    <i nz-icon nzType="delete"></i>
  </button>
</div>
