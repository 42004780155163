import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormInputUploadImages } from "@app/admin/base/form-input-upload-images/comp";
import { BaseFormItem } from "@app/admin/base/form-item";
import { Const } from "@const/Const";
import { DialogService } from "@dialogs/dialog.service";
import { DateUtil } from "@services/date-utils";
import { Utils } from "@services/utils";
import {InternalMessageService} from '@services/internal-message.service';
import { DispatchService } from "../../dispatchService"; 
import RouteEntity from "../../entity/RouteEntity";
import { ResponseAdminRouteNoteUI } from "./interfaces";
import { ResponseWhenBy_User } from "@wearewarp/types/rest-api/common";
import { getLinkDashboard } from "@services/routerlink";

@Component({
  selector: "note-list",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})
export class NoteList extends BaseFormItem implements OnInit {
  @ViewChild('uploadImagesForm', { static: false }) uploadImagesForm: FormInputUploadImages;

  private _jobId: string;

  @Input() get jobId() {
    return this._jobId
  };
  set jobId(value) {
    this._jobId = value;
    if (value) {
      setTimeout(() => {
        this.fetchData();
      }, 100);
    }
  }
  @Input() visible = true;
  @Output() visibleChange = new EventEmitter<boolean>();
  newNoteMsg: string;
  uploadImages = [];
  edittingId: number = -1;
  items: {[key: string]: ResponseAdminRouteNoteUI[]} = {};
  pinnedItems: ResponseAdminRouteNoteUI[] = [];
  isLoading: boolean = true;
  public carrierId: string;
  public bidId: string;
  public routeCode: string;
  protected route: RouteEntity;
  constructor(
    private messageService: InternalMessageService, 
    private dispatchService: DispatchService
  ) {
    super();
  }

  ngOnInit(): void {
    this.fetchData();
    this.subscription.add(
      this.dispatchService.routeData$.subscribe(async () => {
        this.route = this.dispatchService.getRoute();
        this.routeCode = this.route.getCode();
        this.carrierId = this.route.getCarrierId();
        if(this.carrierId) this.getDataCarrierBid();
      })
    )
  }

  onDataChange() {
    this.fetchData();
  }

  $formatDate(date: Date) {
    return DateUtil.dateToString(date, "hh:mm A MMM D");
  }

  formatData(data: ResponseAdminRouteNoteUI[]) {
    let items: {[key: string]: ResponseAdminRouteNoteUI[]} = {};
    let pinnedItems: ResponseAdminRouteNoteUI[] = [];
    for (let note of data) {
      if (Utils.isArrayNotEmpty(note?.attachedFiles)) {
        for (let item of note.attachedFiles) {
          item.url = this.attachedFileUrl(item);
        }
      }
      let date = note.insert?.when ?? '';
      if (note.pin?.when && note.pin?.by) {
        pinnedItems.push(note);
        note.time = DateUtil.dateToString(date, "MM/DD/YY hh:mm A");
        continue;
        //nếu note được pined thì đẩy lên đầu và không hiển thị lại ở phía dưới.
      }

      note.time = DateUtil.dateToString(date, "hh:mm A")


      //group note by day
      if (DateUtil.isToday(date)) date = "Today";
      else if (DateUtil.isYesterday(date)) date = "Yesterday";
      else date = DateUtil.dateToString(date, Const.FORMAT_GUI_DATE);


      if (!items[date]) items[date] = [];
      items[date].push(note);
    }
    this.pinnedItems = pinnedItems.sort(function (a, b) {
      let aDate = new Date(a.pin.when);
      let bDate = new Date(b.pin.when);
      return aDate.getTime() < bDate.getTime() ? 1 : (aDate.getTime() > bDate.getTime() ? -1 : 0)
    })
    return items;
  }

  fetchData() {
    this.startProgress();
    this.isLoading = true;
    this.items = {};
    const url = `${Const.APIURI_CONVERSATIONS}/for-route/${this.jobId}`;
    this.api.GET(url).subscribe(
      (resp) => {
        const listData = resp?.data?.list_data || [];
        this.items = this.formatData(listData);
        this.stopProgress();
        this.isLoading = false;
        this.messageService.sendMessage({key:"note_change", subjectId:this.jobId, data:listData.filter(item=>item.pinned)});
      },
      (err) => {
        this.showErr(err);
        this.stopProgress();
        this.isLoading = false;
      }
    );
  }

  close = () => {
    this.newNoteMsg = "";
    this.visibleChange.emit(false);
  };

  onAdd = () => {
    if (!this.newNoteMsg && this.uploadImages.length == 0) return;
    //call API for add new note
    this.startProgress();
    this.isLoading = true;
    let formData = new FormData();
    const jsonData = {
      'content': this.newNoteMsg,
      'countImages': this.uploadImages.length
    }
    formData.append("params", JSON.stringify(jsonData));
    let count = 0;
    for (let image of this.uploadImages) {
      formData.append(`uploadNoteImages.${count}`, image.file, image.fileName);
      count++;
    }
    this.api
      .postFormData(`${Const.APIURI_CONVERSATIONS}/?subjectId=${this.jobId}&subjectType=job&type=note`, formData)
      .subscribe(
        (resp) => {
          this.showSuccess("Your Note has been added successfully.");
          this.newNoteMsg = "";
          this.uploadImages = [];
          this.stopProgress();
          this.isLoading = false;
          this.fetchData();
          this.uploadImagesForm.resetFormInput();
        },
        (err) => {
          this.showErr(err);
          this.newNoteMsg = "";
          this.uploadImages = [];
          this.stopProgress();
          this.isLoading = false;
          this.uploadImagesForm.resetFormInput();
        }
      );
  };

  onEdit = (item) => {
    this.newNoteMsg = item.content;
    this.edittingId = item.id;
  };

  onSubmitEdit = () => {
    this.startProgress();
    this.isLoading = true;
    this.api
      .PUT(
        `${Const.APIURI_CONVERSATIONS}/${this.edittingId}/?subjectId=${this.jobId}&subjectType=job&type=note`,
        {
          content: this.newNoteMsg,
        }
      )
      .subscribe(
        (resp) => {
          this.isLoading = false;
          this.showSuccess("Your Note has been edited successfully.");
          this.onCancelEdit();
          this.fetchData();
          this.stopProgress();
        },
        (err) => {
          this.showErr(err);
          this.onCancelEdit();
          this.stopProgress();
          this.isLoading = false;
        }
      );
  };

  onCancelEdit = () => {
    this.edittingId = -1;
    this.newNoteMsg = "";
  };

  onBtnPin(item) {
    this.startProgress();
    this.isLoading = true;
    this.api
      .PUT(
        `${Const.APIURI_CONVERSATIONS}/${item.id}/pin?subjectId=${this.jobId}&subjectType=job&type=note`,
        { pin: true }
      )
      .subscribe(
        (resp) => {
          this.isLoading = false;
          this.showSuccess("Your Note has been pinned successfully.");
          this.fetchData();
          this.stopProgress();
        },
        (err) => {
          this.showErr(err);
          this.stopProgress();
          this.isLoading = false;
        }
      );
  }

  onBtnUnPin(item) {
    this.confirmDeletion({
      message: "Do you want to unpin this note?",
      txtBtnOk: this.txtDelete,
      fnOk: () => {
        this.startProgress();
        this.isLoading = true;
        this.api
          .PUT(
            `${Const.APIURI_CONVERSATIONS}/${item.id}/pin?subjectId=${this.jobId}&subjectType=job&type=note`,
            { pin: false }
          )
          .subscribe(
            (resp) => {
              this.isLoading = false;
              this.showSuccess("Your Note has been un-pinned successfully.");
              this.fetchData();
              this.stopProgress();
            },
            (err) => {
              this.showErr(err);
              this.stopProgress();
              this.isLoading = false;
            }
          );
      },
    });
  }

  getDateItems() {
    return Object.keys(this.items);
  }

  onFileImageSelectedChange(files) {
    this.uploadImages = files;
  }

  viewImageItem(imgUrl) {
    if (!imgUrl) return;
    DialogService.previewImgs([imgUrl], 0);
  }

  get shouldShowBtnAddNote() {
    if (this.isLoading) return false;
    if (!this.newNoteMsg && this.uploadImages.length == 0) return false;
    return true;
  }

  get shouldShowBtnCancel() {
    if (this.isLoading) return false;
    if (this.edittingId != -1 || this.newNoteMsg || this.uploadImages.length > 0) return true;
    return false;
  }

  getLabelBtnAdd() {
    if (this.edittingId != -1) return 'Update';
    return 'Add';
  }

  getAuthorName(note: ResponseAdminRouteNoteUI, defaultValue = ''): string {
    const name = note?.insert?.by?.name;
    if (!name) return defaultValue;
    let suffix = '';
    switch (note.insert.by.entity) {
      case 'carriers':
        suffix = ' (Carrier)';
        break;
      case 'drivers':
        suffix = ' (Driver)';
        break;
      case 'clients':
        suffix = ' (Customer)';
        break;
    }
    return name + suffix;
  }

  // public getCarrierName(note: ResponseAdminRouteNoteUI): string {
  //   return note.insert.by.entity == 'carriers' ? note.insert.by.name : '';
  //   // let name = super.getCarrierName(carrier);
  //   // if (name) name += ' (Carrier)';
  //   // return name;
  // }

  public isExfreight(note: ResponseAdminRouteNoteUI) {
    return note.insert?.by?.entity == 'carriers' && note.insert?.by?.id == Const.CarrierId.exfreight;
    // return note?.insert?.byCarrierId == Const.CarrierId_Exfreight;
  }

  private async getDataCarrierBid() {
    let jobId = this.route.getId();
    let url = `${Const.APIURI_CARRIER_BIDS}/get/detailByJobId?jobId=${jobId}`;
    const resp = await this.api.GET(url).toPromise();
    if (resp.data?.id) {
      this.bidId = resp.data.id;
    }
  }

  canEditNote(item: ResponseAdminRouteNoteUI): boolean {
    return this.authUser.id === item.insert.by.id;
  }

  shouldShowUpdate(item: ResponseAdminRouteNoteUI): boolean {
    if (!item.update?.when) return false;
    // Nếu note đã bị thằng khác edit thì show lên thằng nào edit.
    // Hiện nay đã ko có edit note của người khác nữa, tuy nhiên vẫn cần hiển thị cho data cũ.
    return item.insert.by.entity != item.update.by.entity || item.insert.by.id != item.insert.by.id;
  }

  getHyperLink(whenBy: ResponseWhenBy_User) {
    return getLinkDashboard(whenBy);
  }

}
