import { Injectable } from "@angular/core";
import { Const } from "@const/Const";
import { ApiService } from "@services/api.service";
import { FilterInfo } from "@wearewarp/types/data-model";

@Injectable()
export class FilterInfoService {
  constructor(private api: ApiService) {}

  public create(data: Partial<FilterInfo> = {}) {
    return this.api.POST(Const.APIURI_FILTER_INFO, data)
  }

  public update(filterId: string, data: Partial<FilterInfo> = {}) {

    return this.api.PUT(`${Const.APIURI_FILTER_INFO}/${filterId}`, data);
  }

  public delete(filterId: string) {
    return this.api.DELETE(`${Const.APIURI_FILTER_INFO}/${filterId}`)
  }
}
