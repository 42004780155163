import { Component, ElementRef, ViewChild, TemplateRef } from "@angular/core";
import { DispatchService } from "../../dispatchService";
import { ActivatedRoute } from "@angular/router";
import { Observable, Subscription, merge } from "rxjs";
import RouteEntity from "../../entity/RouteEntity";
import {
  ModalHelper,
  UpdateEta, FormDataUpdateEta, FormDataUpdateEquipments, UpdateEquipments, FormDataUpdateTaskStatus,
  FormDataUpdateClassifications, UpdateClassifications, UpdateTaskStatus
} from '@wearewarp/ng-antd';
import { DialogService } from "@dialogs/dialog.service";
import { AddDriverLocation } from "../driver-location";
import { AssignPic } from "../assign-pic";
import { Utils } from "@services/utils";
import { Const } from "@const/Const";
import { AdjustRouteShipmentScreen } from "@app/admin/route/screens/adjust-shipment";
import { EditRouteSequenceScreen } from "@app/admin/route/screens/edit-sequence";
import { DateUtil } from "@services/date-utils";
import { MasterData } from "@services/master.data";
import { FormDataAddReasonCode, JobUpdateFormId } from "@wearewarp/types/rest-api/admin/form-data/dispatch";
import { Log } from "@services/log";
import { LoadingComponent } from "@app/admin/components/loading/loading.component";
import { VehicleSelector } from "@app/admin/components/common/vehicle-selector";
import { SnoozeForm } from "../snooze";
import { ManualCompleteTaskForm } from "../manual-complete-task"
import { UIHelper } from "@services/UIHelper";
import StopEntity from "../../entity/StopEntity";
import {Const as WarpConst} from "@wearewarp/universal-libs";
import { DriverDownTime } from '@app/admin/dispatch/components/carrier-and-driver/driver-down-time';
import { BaseComponent } from '@abstract/BaseComponent';
import { MergeRouteShipmentScreen } from "@app/admin/route/screens/merge";
import { FormDataActualTime } from "@wearewarp/types/rest-api/admin/form-data/dispatch";
import { UpdateArrivedTime } from "../update-actual-time";
import { AddReasonCode } from "../add-reason-code";
import { ConfirmCancelShipmentPopup } from "../confirm-cancel-shipment-popup";
import { ApiService } from "@services/api.service";
import { BasicRouteInfo } from "../basic-route-info";
import { BizUtil } from "@services/biz";
import { NzModalRef } from "ng-zorro-antd/modal";
import { CloneRouteDialog } from "../clone-route/clone-route-dialog";
import { CreateTONURouteDialog } from '../create-tonu-route-dialog';
import { UpdateEquipmentUnassignCarrier } from "../update-equipment-unassign-carrier";

@Component({
  selector: 'dispatch-route-header',
  templateUrl: './index.html',
  styleUrls: [
    './index.scss',
  ]
})
export class DispatchRouteHeader extends BaseComponent {
  public isLoading = true;
  public displayInfo: any = {};
  protected subscription: Subscription = new Subscription();
  protected route: RouteEntity;
  public jobHyperLink;
  public trackingTasks = [];
  private dlgCreateManualLoadDone: NzModalRef;
  public dlgManualLoadDoneHyperLink;
  @ViewChild('jobLink') jobLink: ElementRef<HTMLAnchorElement>;
  @ViewChild('tplCloneFromRouteDone') tplCloneFromRouteDone: TemplateRef<any>;

  constructor(
    public activatedRoute: ActivatedRoute,
    private dispatchService: DispatchService,
    private modalHelper: ModalHelper,
  ) {
    super(activatedRoute);
  }

  ngOnInit(): void {
    this.subscription.add(
      this.dispatchService.routeData$.subscribe(() => {
        this.route = this.dispatchService.getRoute();
        this.buildDisplayInfo()
      })
    )
    this.subscription.add(
      this.dispatchService.loading.subscribe(value => {
        this.isLoading = value;
      })
    )
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

  private async buildDisplayInfo() {
    if (!this.route) return;
    this.jobHyperLink = this.createHyperLinkForJob(this.router.url);
    const shipmentIds = this.route.getShipments()?.map(sh => sh.getId()) || [];
    this.displayInfo = {
      code: this.route.getCode(),
      clientText: this.route.getClients()?.map(it => it.name).join(', '),
      id: this.route.getId(),
      status: this.route.getStatus(),
      picName: this.getFullName(this.route.getPic()) || 'Assign Dispatcher',
      firstLocation: this.getCityFirstStop(),
      lastLocation: this.getCityLastStop(),
      equipments: this.getEquipments(),
      tempRange: this.getTempRange(),
      classificationSettings: this.route.getClassificationSettings(),
      onHold: this.getShipmentOnHold(),
      driverDownTime: this.getDriverDownTime(),
      carrier: this.route.getAssignedCarrier(),
      isGhostLoad: this.route.isGhostLoad(),
      isExternalRoute: this.route.isExternalRoute(),
      isLinehaul: this.route.isLinehaul(),
      isLinehaulNoShipment: this.route.isLinehaul() && !shipmentIds.length,
      isLinehaulHasShipment: this.route.isLinehaul() && shipmentIds.length,
      isSourceMarketplace: this.route.isSourceMaketplace(),
      rating: this.route.getRating(),
      isTonuRoute: this.isTonuRoute,
    }
    this.fetchJobTrackingTasks([this.displayInfo.id]);
  }

  getLabelGhostLoad() {
    return this.route.isSourceMaketplace() ? 'Marketplace' : 'Ghost Load';
  }

  get isShowRateCarrier() {
    return this.displayInfo.id && (this.displayInfo.status === WarpConst.JobStatus.completed || this.displayInfo.status === WarpConst.JobStatus.inProgress);
  }

  public thumupCarrierRateOptions = [
    'Carrier responded actively during this route',
    'Carrier used WARP driver app or open tracking url actively',
    'Carrier picked up on-time',
    'Carrier delivered on-time',
  ]
  
  public thumdownCarrierRateOptions = [
    'Carrier did not response actively during this route',
    'Carrier did not use WARP driver app or open tracking url',
    'Carrier picked up lately',
    'Carrier delivered lately',
  ]

  onBtnCopyRouteLink() {
    // 2022/08/10 - Quang: trước đây là gọi vào dispatch detail rồi dùng ClipboardItem để tạo nội dung write vào clipboard.
    // Tuy nhiên Firfox ko support ClipboardItem, ngoài ra chạy trên Chrome cũng thấy nhiều người kêu ko copy được hyper link (chưa rõ nguyên nhân).
    // Nên đành làm theo cách củ chuối là tạo thẻ a trên UI -> khi nào click copy thì select all -> gọi lệnh copy
    // this.copyRouteLink.emit();
    Utils.selectAll(this.jobLink.nativeElement);
    document.execCommand('copy');
    this.notification.create('success', '', 'The link has been copied.');
  }

  createHyperLinkForJob(url: string = null) {
    const shipments = this.route.getShipments().map(item => item?.toJSON());
    let firstShipment = shipments[0];
    let hyperLinkUrl = url?.split('?')?.[0];
    const clients = this.route.getClients();
    const clientName = clients?.[0]?.name || 'Customer N/A';
    let hyperLinkText = `${clientName} / ${this.getMyWarpId(firstShipment)}`;
    if (shipments.length > 1) {
      hyperLinkText += ` (+${shipments.length - 1})`;
    }
    hyperLinkText += ` / Route ${this.route.getCode()}`;
    if (!hyperLinkUrl) {
      hyperLinkUrl = `${Const.routeAdminDispatchList}/${this.route.getId()}`;
    }
    return { hyperLinkText, hyperLinkUrl };
  }

  private getMyWarpId(data) {
    return data?.code || data?.warpId || data?.id || data?._id || 'Shipment N/A';
  }

  private getCityFirstStop() {
    const cities = this.route.getStops().map(item => item.getAddress().city);
    if (Utils.isArrayNotEmpty(cities)) {
      return cities[0];
    } else return 'N/A';
  }

  private getCityLastStop() {
    const cities = this.route.getStops().map(item => item.getAddress().city);
    if (Utils.isArrayNotEmpty(cities)) {
      return cities[cities.length - 1];
    } else return 'N/A';
  }

  public getFullName(user) {
    if (!user) return '';
    if (user.fullName) return user.fullName;
    let text = user.firstName || '';
    if (user.lastName) {
      if (text) {
        text += ' ';
      }
      text += user.lastName;
    }
    return text;
  }

  private getShipmentOnHold() {
    const shipments = this.route.getShipments()
    const onHold = shipments.filter(it => it.getTags() && it.getTags().indexOf('HOLD') >= 0)
    return onHold.map(it => it.getCodeText())
  }

  private getEquipments() {
    let vehicle = this.route.getRequiredVehicle()
    if (vehicle) {
      if (vehicle.options?.length) {
        return `${vehicle.name} /w ${vehicle.options.map(Utils.capitalize).join(", ")}`
      }
      return vehicle.name
    }
    let shipmentModeId = this.route.getShipmentModeId();
    if (shipmentModeId) {
      let shipmentMode = MasterData.getShipmentModeNameById(shipmentModeId);
      let equipmentId = this.route.getEquipmentId();
      if (equipmentId) {
        let equipment = MasterData.getEquipmenNameById(equipmentId);
        if (equipment) {
          if (shipmentMode) {
            shipmentMode += '/';
          }
          shipmentMode += equipment;
        }
      }
      return shipmentMode;
    }
    return ''
  }

  private getEquipments2() {
    let shipmentModeId = this.route.getShipmentModeId();
    if (shipmentModeId) {
      let shipmentMode = MasterData.getShipmentModeNameById(shipmentModeId);
      let equipmentId = this.route.getEquipmentId();
      if (equipmentId) {
        let equipment = MasterData.getEquipmenNameById(equipmentId);
        if (equipment) {
          if (shipmentMode) {
            shipmentMode += '/';
          }
          shipmentMode += equipment;
        }
      }
      return shipmentMode;
    }
    return ''
  }

  private getTempRange() {
    let arr: string[] = [];
    const shipments = this.route.getShipments();
    for (let shipment of shipments) {
      let tempRange = shipment.getTempRange();
      tempRange = tempRange?.trim();
      if (!tempRange) continue;
      if (!arr.includes(tempRange)) arr.push(tempRange);
    }
    return arr.join(', ');
  }


  onClickAction(action) {
    switch (action) {
      case 'edit-sequence':
        if (this.route.getShipmentType() == Const.ShipmentTypes.fullTruckLoad) {
          const shipment = this.route.getShipments()?.[0];
          DialogService.showDialog(`This feature is only available for <b>LTL</b>.
           If you want to change the sequence of <b>FTL shipment</b>,
           please change them in the shipment details screen. <br /><br />
           <a href="${shipment.getDetailUrl()}" target="_blank">Go To Shipment Detail</a>
           `)
          return;
        }
        this.openDrawer({
          title: "Edit Route Sequence",
          component: EditRouteSequenceScreen
        })
        break;
      case 'adjust-shipment':
        if (this.route.getShipmentType() == Const.ShipmentTypes.fullTruckLoad) {
          const shipment = this.route.getShipments()?.[0];
          DialogService.showDialog(`This feature is only available for <b>LTL</b>.
           If you want to add/remove <b>FTL shipment</b> stops,
           please change them in the shipment details screen. <br /><br />
           <a href="${shipment.getDetailUrl()}" target="_blank">Go To Shipment Detail</a>
           `)
          return;
        }
        this.openDrawer({
          title: "Add/Remove Shipments",
          component: AdjustRouteShipmentScreen
        })
        break;
      case "cancel-route":
        this.modalHelper.confirmDelete({ 
          message: 'Please confirm you are sure you want to cancel route.', 
          fnOk: () => this.cancelRoute(),
          txtBtnOk: 'Continue'
        })
        break;
      case 'reload-route':
        this.dispatchService.refresh();
        break;
      case "confirm-all-pods":
        this.modalHelper.confirmYesNo('This action will confirm all PODs belonging to this route. Be careful when performing this action.', () => this.confirmAllPODs())
        break;
      case "merge-route":
        if (this.route.getShipmentType() == Const.ShipmentTypes.fullTruckLoad) {
          const shipment = this.route.getShipments()?.[0];
          DialogService.showDialog(`This feature is only available for <b>LTL</b>.
           If you want to merge <b>FTL shipment</b>,
           please change them in the shipment details screen. <br /><br />
           <a href="${shipment.getDetailUrl()}" target="_blank">Go To Shipment Detail</a>
           `)
          return;
        }
        this.openDrawer({
          title: "Merge Route",
          component: MergeRouteShipmentScreen
        })
        break;
      case 'cancel-ghost-load':
        this.modalHelper.confirmDelete({
          message: 'Please confirm you are sure you want to cancel route.',
          fnOk: () => this._onBtnSubmitCancelRouteGhostLoad(),
          txtBtnOk: 'Continue'
        })
        break;
      case 'show-basic-info':
        this.modalHelper.open(BasicRouteInfo, {nzComponentParams: {jobId: this.route.getId()}});
        break;
      case 'clone-from-route':
        DialogService.openFormDialog1(CloneRouteDialog, {
          nzClosable: false,
          nzMaskClosable: false,
          nzClassName: 'modal',
          nzWidth: '1000px',
          nzComponentParams: {
            routeId: this.route.getCode(),
            onSubmitSucceeded: (resp) => {
              this.showDialogCreateManualLoadDone(resp.data);
              this.refreshService();
            }
          }
        })
        break;
      case 'create-tonu-route':
        let modelData = {
          carrierTONU: null,
          customerTONU: null,
          cancelReason: null,
        }
        DialogService.openFormDialog1(CreateTONURouteDialog, {
          nzClosable: false,
          nzMaskClosable: false,
          nzClassName: 'modal',
          nzWidth: '500px',
          nzComponentParams: {
            jobId: this.route.getId(),
            model: modelData,
            onSubmitSucceeded: (resp) => {
              this.dispatchService.refresh();
            }
          }
        })
        break;
      default:
        DialogService.showDialog("The feature is under development. We will release this feature soon. Sorry for the inconvenience.");
    }
  }

  private cancelRoute() {
    if (this.route.isAssignedCarrier()) {
      return this.notification.create('error', '', 'Only routes that have not been assigned a carrier can be canceled.');
    }
    const modalRef = this.modalHelper.open(ConfirmCancelShipmentPopup, {
      nzWidth: '800px',
      nzTitle: `Also cancel shipment?`,
      nzFooter: null,
      nzComponentParams: {
        onCancelShipment: () => {
          this.modalHelper.confirmYesNo(`You just chose to cancel shipment. Are you sure?`, async () => {
            return await this._onBtnSubmitCancelRoute(true);
          })
          modalRef.close();
        },
        onCancelRouteOnly: async () => {
          await this._onBtnSubmitCancelRoute(false);
          modalRef.close();
        }
      }
    });
  }

  async _onBtnSubmitCancelRoute(cancelShipments: boolean = true) {
    const tasks = this.route.getTasksPickup().map(it => it?.toJSON());
    const params: FormDataUpdateTaskStatus = {
      status: Const.TaskStatus.canceled,
      cancelShipments: cancelShipments,
    }
    await this.dispatchService.updateTaskStatus({
      tasks,
      data: params
    });
    await new Promise(r => setTimeout(r, 3000))
    this.dispatchService.refresh();
  }

  async _onBtnSubmitCancelRouteGhostLoad() {
    const pickTasks = this.route.getTasksPickup().map(it => it?.toJSON());
    const pickParams: FormDataUpdateTaskStatus = {
      status: Const.TaskStatus.canceled,
      cancelShipments: false,
    }
    await this.dispatchService.updateTaskStatus({
      tasks: pickTasks,
      data: pickParams
    });

    const dropTasks = this.route.getTasksDropoff().map(it => it?.toJSON());
    const dropParams: FormDataUpdateTaskStatus = {
      status: Const.TaskStatus.pickupFailed,
      cancelShipments: false,
    }
    await this.dispatchService.updateTaskStatus({
      tasks: dropTasks,
      data: dropParams
    });

    await new Promise(r => setTimeout(r, 3000))
    this.dispatchService.refresh();
  }

  loadingRef;
  private confirmAllPODs() {
    this.loadingRef = DialogService.openFormDialog1(LoadingComponent, {});
    let url = `${Const.APIV2(Const.APIURI_JOBS)}/${this.dispatchService?.getRoute()?.getId()}/confirm_all_pods`;
    this.api.POST(url, {}).subscribe(
      (resp) => {
        this.notification.create('success', '', 'All PODs have been confirmed.');
        this.loadingRef?.close();
        this.dispatchService?.refresh();
      },
      (err) => {
        this.notification.create('error', '', err);
        this.loadingRef?.close();
      }
    );
  }

  public get mileage(): string {
    if (!this.route) return 'N/A'
    const totalDistance = this.route.getTotalDistance();
    if (!totalDistance) return 'N/A'
    return (totalDistance / 1609.34).toFixed(2).toLocaleString()
  }

  public get hours(): string {
    if (!this.route) return 'N/A'
    const totalTime = this.route.getTotalTime();
    if (!totalTime) return 'N/A'
    return (totalTime / 3600.00).toFixed(2).toLocaleString()
  }

  public get latestEta(): string {
    if (!this.route) return 'N/A'
    const data = this.route.getEtaForCurrentStop();
    if (!data) return '';
    const { etaTime, timezone } = data;
    if (!etaTime) return '';
    const time = DateUtil.displayTimeWindow(etaTime, {
      timezone: timezone,
      formatDateOnly: 'MM/DD/YY',
      format: "MM/DD/YY HH:mm",
    })
    const timezoneText = this.getDisplayTimezone(timezone);
    return `${time} ${timezoneText}`
  }

  private getDisplayTimezone(timezone) {
    return DateUtil.timezoneStandardToUsShort(timezone)
  }

  private openDrawer({ title, component }) {
    const ref = this.drawerService.create({
      nzContent: component,
      nzClosable: false,
      nzWidth: "90%",
      nzBodyStyle: {
        padding: "0"
      },
      nzContentParams: {
        routeId: this.route.getId(),
        onFinish: () => {
          ref.close();
          this.dispatchService.refresh()
        }
      }
    });
  }

  public onBtnAddDriverLocation() {
    DialogService.openFormDialog1(AddDriverLocation, {
      nzComponentParams: {
        jobId: this.dispatchService?.getRoute()?.getId(),
        closeOnSuccess: true,
        updateSuccess: resp => {
          this.dispatchService.refresh();
        }
      },
      nzClassName: 'modal',
    });
  }

  public onBtnAssignPiC() {
    DialogService.openFormDialog1(AssignPic, {
      nzComponentParams: {
        jobId: this.dispatchService?.getRoute()?.getId(),
        closeOnSuccess: true,
        updateSuccess: resp => {
          this.dispatchService.refresh();
        }
      },
      nzClassName: "modal assign-driver-form",
    });
  }

  public onBtnUpdateEquipment() {
    const carrierId = this.route.getAssignedCarrier()?.carrierId;
    const jobStatus = this.route.getStatus();
    if (carrierId && jobStatus != WarpConst.JobStatus.completed && jobStatus != WarpConst.JobStatus.inProgress) {
      this.onUpdateEquipmentAlreadyAssignedCarrier();
    } else {
      this.onUpdateEquipmentNotAssignedCarrier();
    }
  }

  private onUpdateEquipmentNotAssignedCarrier() {
    let saving: boolean = false
    let modalRef: any = null
    modalRef = this.modalService.create({
      nzTitle: 'Update Equipments',
      nzContent: VehicleSelector,
      nzComponentParams: {
        value: this.route.getRequiredVehicle(),
        quoting: false,
        withOptions: true,
      },
      nzFooter: [
        {
          label: 'Save',
          type: 'primary',
          loading: () => saving,
          disabled: (componentInstance) => !componentInstance.value || saving,
          onClick: (componentInstance) => {
            saving = true
            this.updateVehicle(componentInstance.value).subscribe(
              (res) => {
                modalRef?.destroy()
                this.dispatchService.refresh();
              },
              (err) => {
                saving = false
                this.notification.create('error', '', err.message);
              }
            )
          }
        },
        {
          label: 'Cancel',
          type: 'default',
          disabled: () => saving,
          onClick: () => modalRef?.destroy()
        },
      ]
    })
  }

  private onUpdateEquipmentAlreadyAssignedCarrier() {
    DialogService.openDialog1(UpdateEquipmentUnassignCarrier, {
      nzComponentParams: {
        vehicleType: this.route.getRequiredVehicle(),
        carrierName: this.route.getCarrier()?.basicInfo?.name || 'Carrier',
        onSaveChangeVehicle: (data) => this.updateVehicle(data),
        onSaveUnAssignCarrier: () => this.unAssignCarrier(),
        onSubmitSucceeded: () => {
          this.dispatchService.refresh();
        }
      },
      nzClassName: "modal",
    })
  }

  updateVehicle(data) {
    const url = Const.APIV2(`${Const.APIURI_JOBS}/${this.dispatchService.getRoute().getId()}/requiredVehicle`);
    return this.api.PUT(url, data);
  }

  unAssignCarrier() {
    const jobId = this.route.getId();
    const url = `${Const.APIV2(Const.APIURI_JOBS)}/${jobId}/unassign_carrier`;
    return this.api.PUT(url, { code: "warp_cancelled", reason: "WARP cancelled" })
  }

  public onBtnUpdateEquipment2() {
    let shipmentModes = MasterData.getAllShipmentModesV2();
    shipmentModes = shipmentModes.map(item => ({
      value: item.id,
      label: item.name,
      equipments: (item.equipments || []).map(it => ({
        value: it.id,
        label: it.name
      }))
    }))
    let data: FormDataUpdateEquipments = {
      shipmentModeId: this.route.getShipmentModeId(),
      equipmentId: this.route.getEquipmentId()
    };
    this.modalHelper.openForm<FormDataUpdateEquipments, UpdateEquipments>(UpdateEquipments, {
      onSubmitError(err) {
        console.error(err);
      },
      onSubmitSucceeded: (resp) => {
        this.dispatchService.refresh();
      },
      nzTitle: 'Update Equipments',
      nzComponentParams: {
        model: data,
        shipmentModes: shipmentModes,
        submit: (data) => {
          return this.updateForm('equipments', data)
        }
      }
    })
  }

  private updateForm(formId: JobUpdateFormId, data: any) {
    Log.d(`updateForm ${formId} , data: `, data);
    const url = Const.APIV2(`${Const.APIURI_JOBS}/${this.dispatchService.getRoute().getId()}/${formId}`);
    const shipmentIds = this.route.getShipments().map(sh => sh.getId()) || [];
    const params = this.convertData(formId, { ...data, shipmentIds })
    return this.api.PUT(url, params);
  }

  private convertData(formId: JobUpdateFormId, data: any) {
    let params = { ...data };
    if (formId === 'actual-time') {
      const tasks = data.stop.getTasks().map(it => it?.toJSON()) || [];
      const taskIds = tasks.map(it => it.id);
      const shipmentIds = data.stop.getShipments().map(sh => sh.getId()) || [];
      const deliveryIds = data.stop.getShipmentDeliveryInfo().map(item => item.id) || [];
      const locationType = data.stop.getType();

      params = { ...params, taskIds, shipmentIds, deliveryIds, locationType }

      let taskData = [];
      for (let task of tasks) {
        const { statusChangeLog, status } = task;
        const timezone = task.info.addr?.metadata?.timeZoneStandard;

        if (!statusChangeLog[status]) continue;
        if (params.delayCodeId) {
          if (!statusChangeLog[status].info) statusChangeLog[status].info = {};
          statusChangeLog[status].info.delayCodeId = params.delayCodeId;
          statusChangeLog[status].info.delayNote = params.note;
        } else if (statusChangeLog[status]?.info?.delayCodeId) {
          statusChangeLog[status].info.delayCodeId = null;
          statusChangeLog[status].info.delayNote = null;
        }

        if (statusChangeLog[status]) {
          for (let type of ['arrived', 'departed']) {
            if ([undefined, null, 'N/A'].includes(data[type])) continue;
            let changeWhen = DateUtil.convertLocalTime(data[type], timezone).toISOString();
            if (type === 'arrived') {
              statusChangeLog['arrived'] = {
                ...statusChangeLog[status],
                changeWhen
              }
            }
            if (type === 'departed') {
              statusChangeLog[status] = {
                ...statusChangeLog[status],
                changeWhen
              }
            }
          }
        }

        let item: any = {
          taskId: task.id,
          statusChangeLog
        }

        if (data.confirmed) item = { ...item, confirmed: true }

        taskData.push(item);
      }

      params = { taskData, taskIds, shipmentIds, deliveryIds, locationType, stopId: data.stop.getId() }
    }
    return params;
  }

  public onBtnUpdateClassification() {
    this.modalHelper.openForm<FormDataUpdateClassifications, UpdateClassifications>(UpdateClassifications, {
      onSubmitError(err) {
        console.error(err);
      },
      onSubmitSucceeded: (resp) => {
        this.dispatchService.refresh();
      },
      nzTitle: 'Update Classification Settings',
      nzComponentParams: {
        classificationShipmentArr:  Object.keys(Const.ClassificationShipments),
        model: this.displayInfo.classificationSettings || {},
        submit: (data) => {
          return this.updateForm('classification-settings', data)
        }
      },
      nzClassName: 'modal-no-padding',
      nzWidth: 550
    })
  }

  refreshService() {
    this.dispatchService.refresh();
  }

  getIssueAlert() {
    let data: any = this.dispatchService?.getRoute()?.toJSON();
    return data?.issues?.find(issue => [WarpConst.IssueStatus.snoozed, WarpConst.IssueStatus.alert].includes(issue.status));
  }

  get snoozeTime(): number {
    return this.getIssueAlert()?.notifyTime;
  }

  get isAlert(): boolean {
    return this.getIssueAlert()?.status === WarpConst.IssueStatus.alert;
  }

  get isSnoozed(): boolean {
    return this.getIssueAlert()?.status === WarpConst.IssueStatus.snoozed;
  }

  get issueType() {
    return this.getIssueAlert()?.type;
  }

  get alertMessage() {
    return this.getIssueAlert()?.message;
  }

  onBtnSnooze() {
    DialogService.openFormDialog1(SnoozeForm, {
      nzComponentParams: {
        job: this.route.getData(),
        closeOnSuccess: true,
        updateSuccess: async (data) => {
          //convert data to minutes
          const snoozeTime = data.hours * 60 + data.minutes;
          data = {
            snoozeTime: snoozeTime,
            stopId: this.getIssueAlert()?.stopId,
          };
          this.api.POST(
            `${Const.APIV2(Const.APIURI_JOBS)}/${this.route.getData().id}/setup_snooze`,
            data
          ).subscribe(async (resp) => {
            this.dispatchService.refresh()
          })
        },
      },
      nzClassName: 'modal-sm modal-snooze',
      nzCentered: true,
    });

  }

  public onBtnUpdateETA() {
    const stopNeedUpdateETA = this.getStopNeedUpdateETA();
    if (this.isNeedUpdateETA()) {
      this.onUpdateETA(stopNeedUpdateETA[0]);
    }
    else {
      this.notification.create('error', '', 'All ETA have been updated.');
    }
  }

  isNeedUpdateETA() {
    const stopNeedUpdateETA = this.getStopNeedUpdateETA();
    if (stopNeedUpdateETA?.length) {
      return true;
    }
    return false;
  }

  getStopNeedUpdateETA() {
    const stops = this.route?.getStops();
    return stops?.filter(stop => {
      if (stop.getId() === this.getIssueAlert()?.stopId) {
        return true;
      }
      return false;
    })
  }

  private onUpdateETA(stop: StopEntity) {
    const timezone = stop.getTimezone();
    let data: FormDataUpdateEta;
    if (stop.getETA()?.from && stop.getETA()?.to) {
      data = {
        from: new Date(stop.getETA().from),
        to: new Date(stop.getETA().to),
        city: undefined,
        state: undefined,
      };
    } else {
      data = { from: null, to: null, city: undefined, state: undefined };
    }
    this.modalHelper.openForm<FormDataUpdateEta, UpdateEta>(UpdateEta, {
      onSubmitError: (err) => {
        UIHelper.showErr(err);
      },
      onSubmitSucceeded: (resp) => {
        this.dispatchService.refresh();
      },
      nzTitle: `Update ETA for stop ${stop.getIndex() + 1}`,
      nzComponentParams: {
        timezone: timezone,
        model: data,
        states: [
          { label: "US States", items: MasterData.getStatesUS() },
          { label: "Canada Provinces", items: MasterData.getCanadaProvinces() },
        ],
        currentDriverLocationFun: async () => {
          try {
            let currentDriverLocationApi = `${Const.APIV2(Const.APIURI_JOBS)}/${this.dispatchService
              ?.getRoute()
              ?.getId()}`;
            let location = (await this.api.GET(currentDriverLocationApi).toPromise())?.data?.assignedDriver
              ?.currentLocation;
            if (location?.city && location?.state) {
              return `${location.city}, ${location.state}`;
            } else return "";
          } catch (error) {
            console.error("Error get driver location data:", error);
            return "";
          }
        },
        searchCitiesFun: async (key) => {
          try {
            return (await this.api.searchUsCities(key).toPromise())?.data.list_data?.map(
              (it) => `${it.city}, ${it.stateCode}`
            );
          } catch (error) {
            console.error("Error search city data:", error);
            return [];
          }
        },
        getStateDesc: this.getStateDesc,
        submit: (data) => {
          const updateDriverLocation = this.api.POST(
            `${Const.APIV2(Const.APIURI_JOBS)}/${this.dispatchService?.getRoute()?.getId()}/driver_location`,
            data
          );
          const updateEtaTime = this.updateEtaForm("eta-time", { etaTime: data }, stop);
          const mergeActions = merge(updateDriverLocation, updateEtaTime);
          return mergeActions;
        },
      },
    });
  }

  public onBtnUpdateReasonCode() {
    let issues = this.route.getIssues();
    let addReasonCodes = issues.filter(it => it.getType() === WarpConst.IssueType.addReasonCode);
    if (!addReasonCodes.length) return;
    let index = addReasonCodes.findIndex(it => it.getStatus() === WarpConst.IssueStatus.alert);
    let stops = this.route.getStops();
    let currentStop = stops[index];
    if (!currentStop) return;

    const actualArrived = this.getDisplayTime(currentStop, currentStop.getArrivedTime());
    if (!actualArrived) return;
    const delayInfo = currentStop.getFirstTask().getDelay();
    let data: FormDataAddReasonCode = {
      delayCodeId: delayInfo?.delayCodeId,
      delayNote: delayInfo?.note
    }

    DialogService.openFormDialog1(AddReasonCode, {
      nzComponentParams: {
        type: currentStop.getType(),
        model: data,
        stopIndex: Number(index) + 1,
        onSave: data => this.submitAddReasonCode(data, currentStop),
        onRefreshDetailJob: () => {
          this.dispatchService.refresh()
        }
      },
      nzClassName: 'modal-no-padding modal-add-reason-code',
    });
  }

  private submitAddReasonCode(data: any, stop: StopEntity) {
    const url = Const.APIV2(`${Const.APIURI_TASKS}/batchUpdate`);
    const tasks = stop.getTasks().map(it => it?.toJSON()) || [];
    const params = {
      action: 'updateReasonCode',
      data: tasks.map(task => ({
        id: task.id,
        data: data
      }))
    }

    return this.api.POST(url, params);
  }

  public onBtnUpdateTime() {
    let issues = this.route.getIssues();
    let reviewActuals = issues.filter(it => it.getType() === WarpConst.IssueType.reviewActuals);
    if (!reviewActuals.length) return;
    let index = reviewActuals.findIndex(it => it.getStatus() === WarpConst.IssueStatus.alert);
    let stops = this.route.getStops();
    let currentStop = stops[index];
    if (!currentStop) return;
    this.onChangeActualTime(currentStop);
  }

  private getDisplayTime(stop, time) {
    return DateUtil.displayLocalTime(time,
      {
        timezone: stop.getTimezone(),
        format: 'MM/DD/YY h:mm A'
      }
    )
  }

  private onChangeActualTime(stop) {
    const actualArrived = this.getDisplayTime(stop, stop.getArrivedTime());
    const actualDeparted = this.getDisplayTime(stop, stop.getDepartedTime());
    if (!actualDeparted && !actualDeparted) return;
    const delayInfo = stop.getFirstTask().getDelay();
    let data: FormDataActualTime = {
      arrived: actualArrived || 'N/A',
      departed: actualDeparted || 'N/A',
      delayCodeId: delayInfo?.delayCodeId,
      note: delayInfo?.note
    }

    DialogService.openFormDialog1(UpdateArrivedTime, {
      nzComponentParams: {
        type: stop.getType(),
        timezone: stop.getTimezone(),
        appointment: stop.getAppointment(),
        isConfirm: true,
        model: data,
        onSave: data => this.updateForm('actual-time', { ...data, stop }),
        onRefreshDetailJob: () => {
          this.dispatchService.refresh()
        }
      },
      nzClassName: 'modal-no-padding modal-update-time',
    });
  }

  updateEtaForm(formId: JobUpdateFormId, data: any, stop: StopEntity) {
    Log.d(`updateForm ${formId} for ${stop.getType()}, data: `, data);
    const url = Const.APIV2(`${Const.APIURI_JOBS}/${this.dispatchService.getRoute().getId()}/${formId}`);
    const tasks = stop.getTasks().map(it => it?.toJSON()) || [];
    const taskIds = tasks.map(it => it.id);
    const shipmentIds = stop.getShipments().map(sh => sh.getId()) || [];
    const deliveryIds = stop.getShipmentDeliveryInfo().map(item => item.id) || [];
    const locationType = stop.getType();
    const params = { ...data, taskIds, shipmentIds, deliveryIds, locationType }
    return this.api.PUT(url, params);
  }

  public getStateDesc(state): string {
    let obj = state;
    if (typeof state == "string") {
      obj = MasterData.getStateUSByCode(state);
    }
    if (!obj) {
      return state;
    }
    let str = obj.code;
    if (obj.name) {
      str += ` (${obj.name})`;
    }
    return str;
  }

  /**
   * Driver Down Time
   */
  private getDriverDownTime() {
    //author :Do Duy Ngoc
    //chỗ này chưa biết viết như nào
    //phải bổ sung thêm types và có khi phải đổi thành mảng
    const driverDownTime = this.route?.getDriverDownTime()
    let timezone = this.getTimezone(this.route.getStops());
    let from = DateUtil.displayLocalTime(driverDownTime?.from, { timezone, format: Const.FORMAT_GUI_DATETIME_V6 });
    let to = DateUtil.displayLocalTime(driverDownTime?.to, { timezone, format: Const.FORMAT_GUI_DATETIME_V6 });

    return {
      from: from,
      to: to,
      timezoneShort: DateUtil.timezoneStandardToUsShort(timezone),
      formated: DateUtil.displayTimeWindow(driverDownTime, {
        timezone: timezone,
        format: 'ddd MMM DD, h:mm A',
        formatDateOnly: 'ddd MMM DD'
      }),
    }
  }
  onBtnEditDriverDownTime() {
    let currentData = this.route?.getDriverDownTime();
    DialogService.openFormDialog1(DriverDownTime, {
      nzComponentParams: {
        model: currentData,
        timezone: this.getTimezone(this.route.getStops()),
        onSave: data => this.updateDriverDownTime(data)
      },
      nzClassName: 'modal-no-padding',
    });
  }

  getTimezone(stops) {
    const stopInfos = stops.map(stop => stop.getDeliveryInfo());
    const firstPickupStop = (stopInfos || []).filter((stop) => stop.type == "PICKUP")[0];
    const pickupAddress = firstPickupStop?.addr ?? {};
    return pickupAddress?.metadata?.timeZoneStandard ?? 'America/Los_Angeles';
  }

  updateDriverDownTime(data) {
    let url = `${Const.APIURI_JOBS}/${this.route.getId()}/update_driver_down_time`;
    this.api.PUT(url, data).subscribe(
      (resp) => {
        this.dispatchService.refresh();
        this.showSuccess("Driver downtime updated successfully!");
      },
      (err) => {
        this.showErr(err);
      }
    );
  }

  saveDataRatingRoute(jobId) {
    let fn = (data) => {
      const url = `${Const.APIV2(Const.APIURI_JOBS)}/${jobId}/rating`;
      return ApiService.instance.PUT(url, data);
    }
    return fn;
  }

  get canEditEquipment() {
    if (this.displayInfo.isSourceMarketplace) {
      return this.displayInfo.equipments ? false : true;
    }
    return true;
  }

  get isHasTrackingTask() {
    return this.trackingTasks.length > 0;
  }

  get displayTrackingTask() {
    let trackingTask;
    for (let type of Const.TrackingTasksPriorityArr) {
      let tmp = this.trackingTasks?.find(it => it.type === type);
      if (tmp) {
        trackingTask = tmp;
        break;
      }
    }
    return trackingTask;
  }

  get trackingTaskWarning() {
    let trackingTask = this.displayTrackingTask;
    return this.getTrackingTaskText(trackingTask) || '';
  }

  getTrackingTaskText(item) {
    if (!item?.type) return '';
    return Const.JobTrackingTaskConfig[item.type]?.label || '';
  }

  get isShowBtnSNOOZE() {
    let trackingTask = this.displayTrackingTask;
    if (!trackingTask) return false;
    return Const.TrackingTasksAllowSnoozeArr.includes(trackingTask.type);
  }

  fetchJobTrackingTasks(jobIds: any[]) {
    let condition = {
      jobIds: jobIds
    }
    let params = {
      filter: JSON.stringify(condition)
    }
    let qs = new URLSearchParams(params).toString();
    const apiUrl = `${Const.APIURI_JOB_TRACKING_TASKS}/get-for-list-jobs?${qs}`;
    this.api.GET(apiUrl).subscribe(
      (resp) => {
        const listTrackingTasks = resp?.data?.list_data || [];
        this.trackingTasks = listTrackingTasks;
      },
      (err) => {}
    );
  }

  onBtnManualCompleteTask() {
    let trackingTask = this.displayTrackingTask;
    if(!trackingTask) return;
    const stops = this.route.getStops();
    const stop = stops.find(it => it.getId() === trackingTask?.metadata?.stopId);
    DialogService.openFormDialog1(ManualCompleteTaskForm, {
      nzComponentParams: {
        job: this.route.getData(),
        stop: stop?.toJSON(),
        trackingTaskType: trackingTask.type,
        closeOnSuccess: true,
        updateSuccess: async (data) => {
          const params = {
            jobId: this.route.getId(),
            trackingTaskId: trackingTask.id
          };
          let note = `[Tracking task]: \n Task: ${Const.JobTrackingTaskConfig[trackingTask.type]?.label} \n Note: ${data.note ?? 'N/A'}`;
          this.saveNoteJob(note);
          this.api.POST(`${Const.APIURI_JOB_TRACKING_TASKS}/manual-complete-task`, params).subscribe(
            async (resp) => {
              this.dispatchService.refresh()
            }, 
            (err) => {
              this.showErr(err)
            }
          );
        },
      },
      nzClassName: 'modal-sm modal-manual-complete-task',
      nzCentered: true,
    });
  }

  onBtnSnoozeTrackingTask() {
    let trackingTask = this.displayTrackingTask;
    if (!trackingTask) return;
    DialogService.openFormDialog1(SnoozeForm, {
      nzComponentParams: {
        job: this.route.getData(),
        trackingTaskName: Const.JobTrackingTaskConfig[trackingTask.type]?.label,
        closeOnSuccess: true,
        updateSuccess: async (data) => {
          const snoozeTime = (data.hours ?? 0) * 60 + (data.minutes ?? 0);
          const params = {
            snoozeTime: snoozeTime,
            trackingTaskId: trackingTask.id
          };
          let note = `[Tracking task snoozed]: \n Task: ${Const.JobTrackingTaskConfig[trackingTask.type]?.label} \n Snooze duration: ${snoozeTime} minutes \n Note: ${data.note ?? 'N/A'}`;
          this.saveNoteJob(note);
          this.api.POST(`${Const.APIURI_JOB_TRACKING_TASKS}/setup_snooze`, params).subscribe(
            async (resp) => {
              this.dispatchService.refresh()
            }, 
            (err) => {
              this.showErr(err)
            }
          );
        },
      },
      nzClassName: 'modal-sm modal-snooze',
      nzCentered: true,
    });
  }

  private saveNoteJob(note) {
    let formData = new FormData();
    const jsonData = {
      'content': note,
    }
    formData.append("params", JSON.stringify(jsonData));
    this.api.postFormData(`${Const.APIURI_CONVERSATIONS}/?subjectId=${this.route.getData().id}&subjectType=job&type=note`, formData).subscribe(
      (resp) => {}, 
      (err) => {
        this.showErr(err)
      }
    )
  }

  get isArchived() {
    let trackingTasks = this.route.getTrackingTasks();
    return trackingTasks?.archived || false;
  }

  get isShowBtnArchive() {
    return true;
  }

  onBtnUpdateArchiveStatus(jobId, action) {
    this.isLoading = true;
    let newStatus: boolean = null;
    switch (action) {
      case 'archive':
        newStatus = true; break;
      case 'un-archive':
        newStatus = false; break;
      default:
        this.showErr('Invalid action');
        return;
    }
    const params = {
      status: newStatus
    }
    const url = `${Const.APIURI_JOB_TRACKING_TASKS}/${jobId}/archive?`;
    this.api.POST(url, params).subscribe(
      (resp) => {
        this.showSuccess(`Update successfully.`)
        this.isLoading = false;
        this.refreshService();
      },
      (err) => {
        this.showErr(err);
        this.isLoading = false;
      }
    )
  }

  private showDialogCreateManualLoadDone(job) {
    this.dlgManualLoadDoneHyperLink = BizUtil.createHyperLinkForJob(job);
    this.dlgCreateManualLoadDone = this.modalService.create({
      nzContent: this.tplCloneFromRouteDone,
      nzClosable: false,
      nzMaskClosable: false,
      nzCentered: true,
      nzFooter: null,
      nzWrapClassName: 'dialog-manual-load-done'
    });
  }

  closeDialogCreateManualLoadDone() {
    this.dlgCreateManualLoadDone?.close();
  }

  get isTonuRoute() {
    let isTonu = true;
    const tasks = this.route.getTasks();
    const pickupTasks = tasks.filter(t => t.getType() == Const.TaskType.PICKUP);

    if (!pickupTasks.length) return false;
    if (pickupTasks.length) {
      pickupTasks.forEach((task) => {
        if (task.getStatus() != Const.TaskStatus.canceled || !task.getTonu()) isTonu = false;
      })
    }
    
    return isTonu;
  }
}
