import { Component, Input } from "@angular/core";
import { BaseFormDialog1 } from '@dialogs/base-form-dlg1';
import ShipmentEntity from "../../entities/ShipmentEntity";
import RouteEntity from "../../entities/RouteEntity";
import { RouteService } from "../../services/route.service";
import { Const as WarpConst, WarpId } from '@wearewarp/universal-libs';
import { Utils } from "@services/utils";

@Component({
  selector: 'validate-shipment-modal',
  templateUrl: './index.html',
  styleUrls: ['./index.less']
})
export class ValidateShipmentModal extends BaseFormDialog1 {
  @Input() listWarpIds: any[] = [];
  @Input() listShipemnts: ShipmentEntity[] = [];
  @Input() routeEntity: RouteEntity;
  shouldShowAddShipments: boolean = false;
  private msgWarning = "";
  newShipmentOptions: any[] = [];
  listShipmentForTable: any[] = [];
  countShipments = {
    valid: 0,
    invalid: 0
  };
  constructor(
    private routeService: RouteService
    ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.removeBlankItemInListWarpIds();
    this.searchListShipment().then(() => {
      this.newArrTableShipment();
      this.countShipmentValidAndInvalid();
    });
  }

  get isGhostLoad() {
    return this?.routeEntity?.isGhostLoad() || false
  }

  removeBlankItemInListWarpIds() {
    this.listWarpIds = this.listWarpIds.filter(item => item !== '');
  }

  async searchListShipment() {
    if (!this.listWarpIds) return;
    let includeFTL = this.isGhostLoad && this.listShipemnts?.length == 0;
    const result = await this.routeService.getListShipmentForAddMore(
      { condition: { warpIds: this.listWarpIds } },
      includeFTL
    );
    if (!result.length || result.length == 0) return;
    this.newShipmentOptions = result.map(item => new ShipmentEntity().init(item));
  }

  validateShipment(shipment: ShipmentEntity) {
    const isExist = this.listShipemnts.find(s => s.getId() == shipment.getId());
    if (isExist) {
      return `Shipment is already in the route.`;
    }
    if (shipment.getLastJobId()) {
      return `Shipment is already in the another route: `;
    }
    if (this.routeEntity && this.routeEntity.getType() == WarpConst.JobType.ghost) {
      let ghostTasks = this.routeEntity.getGhostTask();
      let taskPickZipcodes = ghostTasks.filter(it => it.getType() == WarpConst.TaskType.PICKUP).map(it => it.getAddress()?.zipcode).filter(x => x);
      let taskDropZipcodes = ghostTasks.filter(it => it.getType() == WarpConst.TaskType.DROPOFF).map(it => it.getAddress()?.zipcode).filter(x => x);
      taskPickZipcodes = Utils.uniqElementsArray(taskPickZipcodes);
      taskDropZipcodes = Utils.uniqElementsArray(taskDropZipcodes);
      let pickZipcode = shipment.getPickInfo()?.addr?.zipcode;
      let dropZipcode = shipment.getDropInfo()?.addr?.zipcode;
      if (!taskPickZipcodes.includes(pickZipcode)) {
        return `The pickup zipcode tasks ${taskPickZipcodes} not include pickup shipment zipcode ${pickZipcode}`;
      }
      if (!taskDropZipcodes.includes(dropZipcode)) {
        return `The dropoff zipcode tasks ${taskDropZipcodes} not include dropoff shipment zipcode ${dropZipcode}`;
      }
    }
    return null;
  }

  newArrTableShipment() {
    let countOrderFTL = 0, countShipmentOther = 0;
    let orderFTLId;
    let check = true;
    this.msgWarning = "";
    this.listShipmentForTable = this.listWarpIds.map(warpId => {
      const shipment: ShipmentEntity = this.newShipmentOptions.find(s => (s.getWarpId() == warpId || s.getCode() == warpId));
      if (!shipment) {
        check = false;
        return {
          warpId: this.isNumber(warpId) ? WarpId.showShipment(warpId) : warpId,
          validate: false,
          reason: "Shipment not found OR Using Cross Dock OR Shipment has been routed"
        }
      }
      const shipmentType = shipment.getShipmentType();
      const reason = this.validateShipment(shipment);
      if (reason) {
        check = false;
        return {
          warpId: WarpId.showShipmentCode(shipment.toJSON()),
          shipmentType: shipmentType,
          validate: false,
          reason,
          shipmentId: shipment.getId(),
          routeId: shipment.getLastJobId(),
          jobCode: shipment.getJobCode(),
          pickInfo: shipment.getPickInfo(),
          dropInfo: shipment.getDropInfo()
        }
      }
      if (shipmentType == WarpConst.ShipmentType.fullTruckLoad) {
        if (!countOrderFTL) {
          countOrderFTL += 1;
          orderFTLId = shipment.getOrderId();
        } else if (orderFTLId != shipment.getOrderId()) {
          countOrderFTL += 1;
        }
      } else {
        countShipmentOther += 1;
      }
      return {
        warpId: WarpId.showShipmentCode(shipment.toJSON()),
        shipmentType: shipmentType,
        validate: true,
        reason: "",
        pickInfo: shipment.getPickInfo(),
        dropInfo: shipment.getDropInfo(),
        onHold: shipment.getTags() && shipment.getTags().indexOf('HOLD') >= 0,
        vehicleType: shipment.getVehilceType()
      }
    });
    if (check) {
      if (countOrderFTL > 1) {
        check = false;
        this.msgWarning = "Only one FTL order can be added to the load.";
      } 
      else if (countOrderFTL) {
        if (countShipmentOther) {
          check = false;
          this.msgWarning = "FTL shipment cannot be added to other shipment types.";
        } else {
          const shipmentFTL = this.listShipmentForTable[0];
          const jobData = this.routeEntity.getData();
          let checkVehicleRequired = this.compareVehicleRequired(shipmentFTL, jobData);
          if (!checkVehicleRequired) {
            check = false;
            this.msgWarning = `Shipment ${shipmentFTL.warpId} require a vehicle type not suitable for job ${jobData.code}`;
            this.listShipmentForTable = this.listShipmentForTable.map(item => {
              return {
                ...item,
                validate: false,
                reason: `Vehicle type required is not suitable`
              }
            });
          }
        }
      }
    }
    this.shouldShowAddShipments = check;
  }

  private compareVehicleRequired(shipment, job) {
    const shVehicleRequired = shipment?.vehicleType || {};
    const jobVehicleRequired = job?.requiredVehicle || {};
    let shVehicleOptions = shVehicleRequired?.options || [];
    let jobVehicleOptions = jobVehicleRequired?.options || [];
    let check = false;
    if (
      shVehicleRequired?.code && shVehicleRequired?.code == jobVehicleRequired?.code
      && shVehicleOptions?.length == jobVehicleOptions?.length
      && JSON.stringify(shVehicleOptions.slice().sort()) == JSON.stringify(jobVehicleOptions.slice().sort())
    ) {
      check = true;
    }
    return check
  }

  get warningMessage() {
    return this.msgWarning || "There are some data you have selected that are not valid for action."
  }

  public listShipmentSelected: (shipments: ShipmentEntity[]) => void = () => {};

  onBtnAddShipment() {
    this.listShipmentSelected(this.newShipmentOptions);
    this.closeDialog();
  }

  countShipmentSelected() {
    return this.listWarpIds?.length;
  }

  countShipmentValidAndInvalid() {
    if (!this.listShipmentForTable?.length) return;
    const result = {valid: 0, invalid: 0};
    this.listShipmentForTable.forEach(item => {
      if(item.validate) result.valid++;
      else result.invalid++;
    });
    this.countShipments = result;
  }

  private isNumber(value: any): boolean {
    return !isNaN(value);
  }

}
