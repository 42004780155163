import { BaseComponent } from "@abstract/BaseComponent";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Const } from "@const/Const";
import { FilterInfo } from "@wearewarp/types/data-model";
import { FilterInfoService } from "./filter-info.service";

@Component({
  selector: "select-filter-info-component",
  templateUrl: "./select-filter-info.html",
  styleUrls: ["./select-filter-info.scss"],
})
export class SelectFilterInfoComponent extends BaseComponent {

  @Input() defaultFilters: Array<Partial<FilterInfo>> = [];
  @Input() source = "web-admin";
  @Input() screen = "";

  @Output() onFilterChange = new EventEmitter();
  @Output() onEdit = new EventEmitter();
  @Output() onDelete = new EventEmitter();

  selectedId: string;
  currentFilterDesc = "";
  currentFilterData: any;
  listFilters: FilterInfo[] = [];
  loading = false;
  options = [];
  selectedOptionId: string;

  constructor(private filterInfoService: FilterInfoService) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.loadData();
  }

  loadData(id?: string) {
    this.loading = true;
    const url = `${Const.APIURI_FILTER_INFO}?source=${this.source}&screen=${this.screen}`;
    this.api.GET(url).subscribe(
      (res: any) => {
        this.listFilters = [...this.defaultFilters, ...(res.data?.list_data || [])];
        this.selectedId = id || undefined;
        this.onSelect(id, false);
        this.loading = false;
      },
      (err) => {
        this.showErr(err);
        this.loading = false;
      }
    );
  }

  onSelect(filterId, emitEvent = true) {
    this.selectedId = filterId;
    this.selectedOptionId = undefined;
    const filter = this.listFilters.find((item) => item.id === filterId);
    if (!filter && !emitEvent) return;

    this.currentFilterDesc = filter?.data?.name || "";
    this.currentFilterData = filter?.data?.filterData || {};
    this.options = filter?.data?.filterData?.options || [];

    this.onFilterChange.emit(filter);
  }

  onSelectOption(value) {
    const filter = this.listFilters.find((item) => item.id === this.selectedId);
    if (!filter) return;
    const option = this.options?.find((it) => it.value === value);
    if (!option) return;

    if (this.selectedOptionId === value) {
      this.selectedOptionId = undefined;
      delete filter.data.filterData[option.key];
    } else {
      this.selectedOptionId = value;
      filter.data.filterData[option.key] = option.value;
    }

    this.onFilterChange.emit(filter);
  }

  onBtnEdit() {
    const filter = this.listFilters.find((item) => item.id === this.selectedId);
    if (!filter) return;

    this.onEdit.emit(filter);
  }

  onBtnDelete() {
    this.loading = true;
    this.delete().subscribe(() => {
      this.onDelete.emit(this.selectedId);
      this.loadData();
    }, (err) => {
      this.showErr(err);
      this.loading = false;
    })
  }

  canEdit() {
    return this.selectedId && !this.isDefault(this.selectedId);
  }

  isDefault(filterId: string) {
    return !!this.defaultFilters.find((item) => item.id === filterId);
  }

  create(formData: any) {
    const { name, ...filterData } = formData;
    return this.filterInfoService.create({screen: this.screen, source: this.source, data: { name, filterData }});
  }

  update(id: string, formData: any) {
    const { name, ...filterData } = formData;
    return this.filterInfoService.update(id, { data: { name, filterData }});
  }

  delete() {
    return this.filterInfoService.delete(this.selectedId);
  }

  clear() {
    this.selectedId = undefined;
    this.currentFilterDesc = "";
    this.currentFilterData = {};
    this.options = [];
    this.selectedOptionId = undefined;
  }

}
