<div class="right10 left10 top10" *ngIf="!isLoading">
  <nz-tabset>
    <nz-tab [nzTitle]="noteTemplate">
      <ng-template #noteTemplate>
        <i nz-icon nzType="form" nzTheme="outline" style="font-size: 18px;" nz-tooltip="Notes"></i>
      </ng-template>
      <note-list [jobId]="jobId"></note-list>
    </nz-tab>
    <nz-tab [nzTitle]="messageTemplate" (nzClick)="startIntervalDriverMessage()" (nzDeselect)="stopIntervalDriverMessage()">
      <ng-template #messageTemplate>
        <nz-badge [nzCount]="driverMessageNumber" [nzOverflowCount]="9" nzSize="small">
          <i nz-icon nzType="comment" nzTheme="outline" style="font-size: 18px;" nz-tooltip="Driver Messages"></i>
        </nz-badge>
      </ng-template>
      <driver-message #driverMessage [jobId]="jobId" ></driver-message>
    </nz-tab>
    <nz-tab [nzTitle]="historyTemplate">
      <ng-template #historyTemplate>
        <i nz-icon nzType="history" nzTheme="outline" style="font-size: 18px;" nz-tooltip="History" (click)="history.getDataLog()"></i>
      </ng-template>
      <history-list #history [objectId]="jobId" entity="jobs" [isCustomTimeline]="true"></history-list>
    </nz-tab>
    <nz-tab [nzTitle]="workTemplate">
      <ng-template #workTemplate>
        <i nz-icon nzType="clock-circle" nzTheme="outline" style="font-size: 18px;" nz-tooltip="Work Log"></i>
      </ng-template>
      <dispatch-work-log-component [jobId]="jobId"></dispatch-work-log-component>
    </nz-tab>
    <nz-tab *ngIf="showEvents" [nzTitle]="titleTemplate">
      <ng-template #titleTemplate>
        <span nz-icon nzType="compass" nzTheme="outline" style="font-size: 18px;" nz-tooltip="Events"></span>
      </ng-template>
      <events-container type="JOB" [id]="jobId"></events-container>
    </nz-tab>
</nz-tabset>
</div>
