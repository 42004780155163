import { BaseComponent } from "@abstract/BaseComponent";
import { Component, Input } from "@angular/core";
import { Const } from "@const/Const";
import { DateUtil } from "@services/date-utils";
import { Utils } from "@services/utils";

@Component({
  selector: "dispatch-work-log-component",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})
export class WorkLogComponent extends BaseComponent {
  @Input() jobId: string;

  workLogs: any[] = [];
  loading: boolean = false;
  error = "";

  // AM PM time and month/day/year
  formatTime = " MM/DD/YY hh:mm A";

  constructor() {
    super();
  }

  ngOnInit() {
    this.getWorkLogs();
  }

  getWorkLogs() {
    if (!this.jobId) return;

    this.loading = true;
    const url = `${Const.API_SERVICE_WORK_QUEUE}/p/history/job/${this.jobId}`;
    this.api.GET(url).subscribe(
      (res: any) => {
        this.workLogs = res.data?.list_data || [];
        this.loading = false;
      },
      (err) => {
        // this.showErr(err);
        this.error = Utils.getErrorString(err);
        this.loading = false;
      }
    );
  }

  displayTime(time: string, tz: string): string {
    if (!tz) return this.displayDateTimeDB(time, this.formatTime);

    return DateUtil.displayLocalTime(time, {
      timezone: tz,
      format: this.formatTime,
    });
  }

  getTaskName(taskType: string): string { // tạm thời hardcode, khi có nhiều thì phải lấy từ bảng work_queues
    switch (taskType) {
      case "departureConfirmation":
        return "Departure Confirmation";
      case "arrivalConfirmation":
        return "Arrival Confirmation";
      case 'routeConfirmation':
        return "Route Confirmation";
      default:
        return taskType;
    }
  }

  getDescFromLog(log): string { // ActionLogHistory from web-work-queue
    const type = log?.type;
    let data = log?.data;
    let actions = log?.actions || [];
    let desc = '';
    for (let action of actions) {
      if (["updateETA", "updateATA"].includes(action)) {
        const taskName = this.getTaskName(log?.task?.type);
        if (taskName) {
          desc = desc + taskName + ' - ';
        }
      }
      switch (action) {
        case 'sendTrackingLink':
          desc = desc + 'Reminder sent to driver turn on live-tracking'; break;
        case 'markDriverRefusedLiveTracking':
          desc = desc + 'Driver refused to turn on live tracking'; break;
        case 'markContactDriverFailed':
          desc = desc + `Contact Driver Failed ${data?.note ? `"${data.note}"` : ''}`; break;
        case 'markContactCarrierFailed':
          desc = desc + `Contact Carrier Failed ${data?.note ? `"${data.note}"` : ''}`; break;
        case 'noteReason':
          if (type === 'driverNotFinishedLoading' || type === 'driverNotFinishedUnloading') {
            desc = desc + `Note delay reason "${data?.delayReason}"`; break;
          }
          if (type === 'driverLate') { desc = desc + `Note driver late reason "${data?.reason}"`; break; }
          if (type === 'driverNotPickup') { desc = desc + `Note driver can't pickup reason "${data?.reason}"`; break; }
        case 'updateETA':
          let eta = this.displayTime(data?.eta, data.timezone);
          desc = desc + `Update ETA: ${eta}`; break;
        case 'updateATA':
          let ata = this.displayTime(data?.ata, data.timezone);
          desc = desc + `Update ATA: ${ata}`; break;
        case 'updateDepartureTime':
          let departureTime = this.displayTime(data?.departureTime, data.timezone);
          desc = desc + `Update Actual Departure Time: ${departureTime}`; break;
        case 'updateEstimateDepartureTime':
          let estimateDepartureTime = this.displayTime(data?.estimateDepartureTime, data.timezone);
          desc = desc + `Update Estimate Departure Time: ${estimateDepartureTime}`; break;
        case 'skipTask':
          desc = desc + 'Skip Task: ' + data?.note;
          break;
        
        // route confirmation actions
        case 'markCarrierKeepRoute':
          desc = desc + 'Marked carrier keep this route'; break;
        case 'markCarrierWaitingForConfirmation':
          desc = desc + `Marked carrier want to keep this route but can't confirm via email during the call. Follow-up after ${data?.delayTime} mins`; break;
        case 'markCarrierDeclinedRoute':
          desc = desc + `Marked carrier declined this route "${data?.reason}"`; break;
        case 'markEquipmentReady':
          desc = desc + 'Marked Equipment as ready'; break;
        case 'markDriverReady':
          desc = desc + 'Marked Driver as ready'; break;
        // otp confirmation actions
        case 'markConfirmedOTP':
          desc = desc + 'Marked OTP Confirmed'; break;
        case 'markIncorrectDriver':
          desc = desc + 'Marked Incorrect Driver'; break;
        case 'markContactedViaSms':
          desc = desc + 'Marked Contacted via Sms/Email'; break;
        default:
          break;
      }
      desc = desc + '\n';
    }
    return desc.trim();
  }

  capitalizeCase(str: string): string {
    return str?.charAt(0).toUpperCase() + str?.slice(1).toLowerCase();
  }

}
