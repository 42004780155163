import { Component, Injector, Inject, Output, EventEmitter } from "@angular/core";
import { BaseInputComponent } from "@app/admin/components/forms/base-custom-input";
import { ApiService } from "@services/api.service";
import { Const } from '@const/Const';

@Component({
    selector: '[search-route-input]',
    templateUrl: './view.html',
    styleUrls: ['./style.scss']
})
export class SearchRouteInput extends BaseInputComponent {
    @Output() onGetRouteDetailSuccess: EventEmitter<any> = new EventEmitter<any>();
    @Output() onGetRouteDetailFail: EventEmitter<any> = new EventEmitter<any>();

    private routeId: string = '';
    public searchKeyword: string = '';

    constructor(@Inject(Injector) protected injector: Injector, private api: ApiService) {
        super(injector);
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    get needUpdate(): boolean {
        if (!this.searchKeyword) return true;
        if (this.routeId === this.searchKeyword) return true;
        return false;
    }

    onSearchRoute(): void {
        if (this.routeId === this.searchKeyword) return;
        this.routeId = this.searchKeyword;
        const url = `${Const.APIV2(Const.APIURI_JOBS)}/get-detail-by-code/${this.routeId}`;
        this.isLoading = true;
        this.api.GET(url).subscribe((resp) => {
            this.isLoading = false;
            this.onGetRouteDetailSuccess.emit(resp);
        }, 
        (err) => {
            this.isLoading = false;
            this.onGetRouteDetailFail.emit(err);
        })
    }

    onInputKeyPress(event: KeyboardEvent) {
        if(event.key == 'Enter') {
            this.onSearchRoute();
        }
    }

}