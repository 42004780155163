import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from "@angular/core";
import { Const } from "@const/Const";
import { DateUtil } from "@services/date-utils";
import { Log } from "@services/log";
import { BaseFormItem } from "@app/admin/base/form-item";
import { ActivatedRoute } from "@angular/router";
import { startOfDay, endOfDay } from "date-fns";
import { Utils } from "@services/utils";
import { ApiService } from "@services/api.service";
import { MasterData } from "@services/master.data";
import { DialogService } from "@dialogs/dialog.service";
import { ReAssignDispatcherComponent } from "@app/admin/dispatch/components/re-assign-dispatcher";
import { FilterModal } from "./filter-modal";
import { SearchNoteDialog } from "../search-note";
import { SelectFilterInfoComponent } from "@app/admin/components/filter-info/select-filter-info";
import { FilterInfo } from "@wearewarp/types/data-model";

@Component({
  selector: "[list-header-filter-v4]",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss", "../../../../../styles/form-v2.scss"],
})
export class DispatchListHeaderFilterV4 extends BaseFormItem {
  @Input() getDefaultFilter: () => any = () => {
    return DispatchListHeaderFilterV4.defaultFilter;
  };
  @Output() onReload: EventEmitter<any> = new EventEmitter<any>();
  public static get defaultFilter() {
    return { status: DispatchListHeaderFilterV4.defaultStatus };
  }
  private static get defaultStatus() {
    return [Const.JobStatus.created, Const.JobStatus.inProgress];
  }

  private get declaration(): FormGroupDeclaration {
    return {
      status: { label: "Status", placeHolder: "Select", notAcceptEmpty: true },
      search: { label: "Search", placeHolder: "Search", notAcceptEmpty: true },
      sort: { label: "Sort", placeHolder: "Select", notAcceptEmpty: true },
      clientId: { label: 'Customer', notAcceptEmpty: true, placeHolder: 'Select' },
      thirdPartyClientId: { label: 'Third Party Client', notAcceptEmpty: true, placeHolder: 'Select' },
      clientFilterType: { label: 'Client Filter Type', placeHolder: 'Select', notAcceptEmpty: true, initialValue: 'include' },
      carrierFilterType: { label: 'Carrier Filter Type', placeHolder: 'Select', notAcceptEmpty: true, initialValue: 'include' },
      shipmentType: { label: 'Shipment Type', placeHolder: 'Select', notAcceptEmpty: true },
      carrierId: { label: 'Carrier', notAcceptEmpty: true, placeHolder: 'Select' },
      driverId: { label: '', notAcceptEmpty: true },
      pickupState: { label: 'Pickup State', placeHolder: 'Select', notAcceptEmpty: true },
      dropoffState: { label: 'Delivery State', placeHolder: 'Select', notAcceptEmpty: true },
      // pickupCountry: {label: ''},
      // dropoffCountry: {label: ''},
      // equipment: { label: 'Equipment', placeHolder: 'Select', notAcceptEmpty: true },
      vehicleTypes: { label: 'Equipment', type: 'array', placeHolder: 'Select', initialValue: [] },
      fromDate: { label: 'From date', type: 'date', notAcceptEmpty: true, getValue: DateUtil.getDateFrom },
      toDate: { label: 'To date', type: 'date', notAcceptEmpty: true, getValue: DateUtil.getDateTo },
      fromTimezone: { label: 'Time zone', placeHolder: 'Time zone', notAcceptEmpty: true},
      toTimezone: { label: 'Time zone', placeHolder: 'Time zone', notAcceptEmpty: true},
      picId: { label: 'Dispatcher', placeHolder: 'Select', notAcceptEmpty: true },
      carrierSaleRepId: { label: 'Carrier Sales Rep', placeHolder: 'Select', notAcceptEmpty: true },
      clientSaleRepId: { label: 'Client Sales Rep', placeHolder: 'Select', notAcceptEmpty: true },
      clientServiceUserId: { label: 'Client Account Owner', placeHolder: 'Select', notAcceptEmpty: true },
      clientServiceRepUserId: { label: 'Client Service Rep', placeHolder: 'Select', notAcceptEmpty: true },
      archivedTrackingTask: { label: 'Task queue', type: 'boolean', placeHolder: 'Select', notAcceptEmpty: true},
      pickupStateFilterType: { label: 'Pickup State Filter Type', placeHolder: 'Select', notAcceptEmpty: true, initialValue: 'include' },
      dropoffStateFilterType: { label: 'Dropoff State Filter Type', placeHolder: 'Select', notAcceptEmpty: true, initialValue: 'include' },
    };
  }

  private get declarationForSysadmin(): FormGroupDeclaration {
    return {
      jobId: { label: "Job ID", notAcceptEmpty: true },
    };
  }

  protected formGroupDeclaration: FormGroupDeclaration = {};

  public listDispatchers = [];
  public listTimezones = DateUtil.listTimezones;
  public allStatuses = Const.JobStatusArray;
  public displayStatuses = this.allStatuses;
  public displayCarrierStatuses = ['Assigned', 'Needed']
  public sortTypes = [
    { name: "Pickup Date - Ascending", value: "pickDt.time,1" },
    { name: "Pickup Date - Descending", value: "pickDt.time,-1" },
    { name: "Delivery Date - Ascending", value: "dropDt.time,1" },
    { name: "Delivery Date - Descending", value: "dropDt.time,-1" },
    { name: "No sort", value: ""}
  ];
  public listShipmentTypes = Const.ShipmentTypesArray;
  public hotlistOptions = [
    "carrierAssignment",
    "etaToPickup",
    "pickupSuccessful",
    "etaToDelivery",
    "deliverySuccessful",
    "mustArriveAtPickupOnTime",
    "mustArriveAtDeliveryOnTime",
  ];
  public search: string = null;
  public loaded: number = 0;
  public page: number = 1;
  public isExporting = false;
  public allStates = MasterData.getStatesUS();
  public selectedStatus = {};
  public countStatusData = {};
  public offsetCustomerFilter = 0;
  public offsetSortFilter = 0;
  private timeOut = null;
  public lastUpdated;
  public requestId;
  public observerResize;


  public countriesStates = MasterData.getCountriesStates_forSelectGroup();

  @ViewChild("form") form: ElementRef<HTMLFormElement>;
  @ViewChild("customerFilter") customerFilter: ElementRef<HTMLElement>;
  @ViewChild("sortFilterDispatch") sortFilterDispatch: ElementRef<HTMLElement>;
  @ViewChild("selectFilterInfo") selectFilterInfo: SelectFilterInfoComponent;

  // //window resize thì tính toán lại chiều cao của dispatch window
  @HostListener("window:resize", ["$event"])
  onWindowResize(event) {
    this.toggleDetailContainerHeight();
  }

  walmartOptions = [
    { key: "thirdPartyClientId", value: "0", label: "Walmart" },
    { key: "thirdPartyClientId", value: "2", label: "Sam's Club" },
    { key: "thirdPartyClientId", value: "1408", label: "West Marine" },
    { key: "thirdPartyClientId", value: "1033", label: "Sherwin Williams" },
    { key: "thirdPartyClientId", value: "1020", label: "The Home Depot" },
    { key: "thirdPartyClientId", value: "1511", label: "At Home" },
    { key: "thirdPartyClientId", value: "1476", label: "Sur La Table" },
    { key: "thirdPartyClientId", value: "1549", label: "Urban Stems" },
    { key: "thirdPartyClientId", value: "1455", label: "PPG" },
  ]

  defaultFilterInfos: Array<Partial<FilterInfo>> = [
    { id: "1", data: { name: "Carrier Assigned", filterData: { carrierId: "yes" }} },
    { id: "2", data: { name: "Carrier Needed", filterData: { carrierId: "no" }} },
    { id: "3", data: { name: "Walmart Only", filterData: { clientId: ["01H22NK3MBXBDCW6AZDWFZ09V0"], clientFilterType: "include", options: this.walmartOptions }}}
  ];

  constructor(protected activatedRoute: ActivatedRoute, private eRef: ElementRef) {
    super();
    this.activatedRoute.queryParams.subscribe((p) => {
      this.search = p.search;
      this.loaded = p.loaded || 0;
      this.page = p.page || 1;
    });
  }

  @Input() searchPlaceHolder = "Search by Load ID, WARP ID, Ref Number";

  ngOnInit(): void {
    if (this.isSysAdmin) {
      this.formGroupDeclaration = { ...this.declarationForSysadmin, ...this.declaration };
    } else {
      this.formGroupDeclaration = this.declaration;
    }
    let params = Utils.parseQueryStringFromUrl(this.router.url);
    if (params.filter) {
      this.model = JSON.parse(params.filter);
      this.isWalmartScheduledLessThanDuration = !!this.model?.isWalmartScheduledLessThanDuration;
    } else if (!params.search) {
      // Nếu ko có cả filter và search thì mới gán filter mặc định
      this.model = this.getDefaultFilter(); //DispatchListHeaderFilterV3.defaultFilter;
    }
    if (params.sort) {
      this.model.sort = params.sort;
    }
    super.ngOnInit();
    this.initResizeSensor();
  }

  ngOnDestroy() {
    this.destroyResizeSensor();
  }



  initResizeSensor() {
    const elem = document.querySelector(".dispatch-container .list-header .box-container");
    if (!elem) return;
    this.observerResize = new ResizeObserver(() => this.toggleDetailContainerHeight());
    this.observerResize.observe(elem);
  }
  destroyResizeSensor() {
    const elem = document.querySelector(".dispatch-container .list-header .box-container");
    if (!elem) return;
    this.observerResize?.unobserve(elem);
  }

  toggleDetailContainerHeight() {
    const headerDom = document?.querySelector(".dispatch-container .list-header");
    const headerHeight = headerDom?.clientHeight;
    const windowHeight = window.innerHeight;
    const contentHeaderHeight = headerDom?.querySelector(".box-container").clientHeight;
    const contentHeaderNewHeight =  90;
    const containerHeight = windowHeight - 120 - contentHeaderNewHeight;
    headerDom?.setAttribute("style", `height: ${contentHeaderNewHeight}px`);
    document
      ?.querySelectorAll(".dispatch-container .dispatch-dynamic-height")
      .forEach((elem) => elem.setAttribute("style", `height: ${containerHeight}px;`));
  }

  clearFilter() {
    for (let key of this.formInputKeys) {
      this.setItemValue(key, null);
    }
    // this.selectedStatus = {};
    this.search = null;
    this.needCarrierChecked = false;
    this.needDriverChecked = false;
    this.needDispatcherChecked = false;
    this.needPOPChecked = false;
    this.isWalmartScheduledLessThanDuration = false;
    this.page = 1;
  }

  doSearch(s) {
    this.search = s;
    this.page = 1;
    this.loadData();
  }

  onRefresh() {
    this.onReload.emit();
    this.loadData();
  }

  getStatusLabel(status) {
    let str = this.getStatusJob(status);
    let count = this.countStatusData[status];
    if (count) {
      str += ` (${count})`;
    }
    return str;
  }
  getCarrierStatusLabel(status: string) {
    let str = 'Carrier ';
    str += status;
    return str;
  }

  private async buildQuery() {
    let q = { page: this.page || 1, loaded: Date.now() };
    if (this.search) {
      q["search"] = this.search;
    }
    let condition: any = this.getFormData_JSON(true);
    // condition = await this.createDataFilterLocation(condition);
    if (condition.status && !Utils.isArrayNotEmpty(condition.status)) {
      delete condition.status;
    }
    if (this.needPOPChecked) {
      condition.isNeedPOD = true;
    }
    if (this.isWalmartScheduledLessThanDuration) {
      condition.isWalmartScheduledLessThanDuration = true;
    }
    if (condition.sort) {
      q["sort"] = condition.sort;
      delete condition.sort;
    }
    if (condition.fromDate && condition.fromTimezone) {
      let timezone = DateUtil.mapTimezoneUS(condition.fromTimezone);
      let date = DateUtil.toBeginOfDay(new Date(condition.fromDate)).toISOString();
      condition.fromDate = DateUtil.convertLocalTime(new Date(date), timezone).toISOString();
    }
    if (condition.toDate && condition.toTimezone) {
      let timezone = DateUtil.mapTimezoneUS(condition.toTimezone);
      let date = DateUtil.toEndOfDay(new Date(condition.toDate)).toISOString();
      condition.toDate = DateUtil.convertLocalTime(new Date(date), timezone).toISOString();
    }
    const f = JSON.stringify(condition);
    if (f.length > 2) {
      q["filter"] = f;
    }
    q["tab"] = Utils.parseQueryStringFromUrl(this.router.url)?.tab || Const.DispatchTab.all_load;
    return q;
  }

  async loadData() {
    this.routeWithQueryUrl(await this.buildQuery());
    // this.countStatus();
  }

  public startOfDay(result: Date): Date {
    return result ? startOfDay(result) : null;
  }
  public endOfDay(result: Date): Date {
    return result ? endOfDay(result) : null;
  }

  isStatusSelected(status) {
    return !!this.selectedStatus[status];
  }
  setDefaultSelectedCarrier() {
    if(Utils.parseQueryStringFromUrl(this.router.url).filter){
      return {
        "Assigned": JSON.parse(Utils.parseQueryStringFromUrl(this.router.url).filter).carrierId == "yes" ? true : false,
        "Needed": JSON.parse(Utils.parseQueryStringFromUrl(this.router.url).filter).carrierId == "no" ? true : false
      }
    }else{
      return {
        "Assigned": false,
        "Needed": false
      }
    }
  }
  //default value for carrier status
  selectedCarrier = this.setDefaultSelectedCarrier();
  isCarrierSelected(status) {
    switch (status) {
      case "Assigned":
        this.selectedCarrier["Assigned"] = !this.selectedCarrier[status];
        this.selectedCarrier["Needed"] = false;
        break;
      case "Needed":
        this.selectedCarrier["Assigned"] = false;
        this.selectedCarrier["Needed"] = !this.selectedCarrier[status];
        break;
    }
  }
  onBtnCarrierStatus(status) {
    // this.selectedCarrier[status] = !this.selectedCarrier[status];
    this.isCarrierSelected(status);
    let data;
    for (let key of Object.keys(this.selectedCarrier)) {
      if (this.selectedCarrier[key]) {
        key == "Assigned" ? data = 'yes' : data = 'no';

      }
    }
    this.setItemValue("carrierId", data);
    this.onChange(data, "carrierId");
  }


  onBtnStatus(status) {
    this.selectedStatus[status] = !this.selectedStatus[status];
    let arr = [];
    for (let key of Object.keys(this.selectedStatus)) {
      if (this.selectedStatus[key]) {
        arr.push(key);
      }
    }
    this.setItemValue("status", arr);
    this.onChange(arr, "status");
  }

  onBtnSort(sort) {
    this.setItemValue("sort", sort);
    this.onChange(sort, "sort");
  }

  public needCarrierChecked = false;
  public needDriverChecked = false;
  public needDispatcherChecked = false;
  public needPOPChecked = false;
  public isWalmartScheduledLessThanDuration = false;
  onCheckboxNeedPOD(value) {
    this.selectedStatus = {};
    if (value) {
      // this.setItemValue("carrierId", null);
      // this.setItemValue("picId", null);
      this.onBtnStatus(Const.JobStatus.completed);
    } else {
      this.setItemValue("status", DispatchListHeaderFilterV4.defaultStatus);
      this.selectedStatus = { created: true, inProgress: true };
    }
  }

  onBtnAllFilter(filterInfo?: FilterInfo) {
    //show modal hear
    const filterModal = DialogService.openFormDialog1(FilterModal,{
     nzClassName: "modal modal-xl",
      nzComponentParams: {
        onApply: (dataFormJson, dataCheckBox) => {
          this.applyFilterData({...dataFormJson, ...dataCheckBox})
          this.selectFilterInfo.clear();
        },
        dataForm: JSON.parse(Utils.parseQueryStringFromUrl(this.router.url)?.filter??"{}"),
        urlMyLoad: Utils.parseQueryStringFromUrl(this.router.url).tab,
        onResetFilter: () => {
          this.clearFilter();
          this.setFormValues({"status": DispatchListHeaderFilterV4.defaultStatus,
        "sort": Utils.parseQueryStringFromUrl(this.router.url).sort});
        },
        needDispatcherChecked: this.needDispatcherChecked,
        needCarrierChecked: this.needCarrierChecked,
        needDriverChecked: this.needDriverChecked,
        needPODChecked: this.needPOPChecked,
        isWalmartScheduledLessThanDuration: this.isWalmartScheduledLessThanDuration,
        page: this.page,
        search: this.search,
        lastUpdated: this.lastUpdated,
        lastUpdatedTime: this.lastUpdatedTime,
        requestId: this.requestId,
        filterInfo: filterInfo,
        onSave: (data, cb) => {
          if (data.filterId) {
            const { filterId, ...dataUpdate } = data;
            this.selectFilterInfo.update(data.filterId, dataUpdate).subscribe((res) => {
              this.selectFilterInfo.loadData(res.data?.id);
              cb?.();
            }, (err) => {
              this.showErr(err);
            })
          } else {
            this.selectFilterInfo.create(data).subscribe((res) => {
              this.selectFilterInfo.loadData(res.data?.id);
              cb?.();
            }, (err) => {
              this.showErr(err);
            })
          }
        }
      }
    })
  }


  contentBtnAllFilter() {
    let count = 0;
    let urlMyLoad = Utils.parseQueryStringFromUrl(this.router.url)?.tab;
    let formData = JSON.parse(Utils.parseQueryStringFromUrl(this.router.url)?.filter??"{}");
    if(urlMyLoad == 'my_load') {
      for( let key of Object.keys(formData)) {
        if(key != 'status' && key != 'picId') {
          count++;
        }
      }
    } else {
      for( let key of Object.keys(formData)) {
        if(key != 'status') {
          count++;
        }
      }
    }
    // if(this.needPOPChecked) count++;
    if(count > 0) {
      return "All Filters (" + count + ")";
    } else {
      return "All Filters";
    }
  }

  getSortText() {
    let sort = this.getItemValue("sort");
    if(sort == "") return "";
    let sortType = this.sortTypes.find((item) => item.value == sort);
    return sortType ? sortType.name : "";
  }
  onChange(value, key) {

    this.page = 1;
    setTimeout(() => {
      this.loadData();
      let statusArr = this.getItemValue("status") ?? [];
      let carrierId = this.getItemValue("carrierId");
      let driverId = this.getItemValue("driverId");
      this.needCarrierChecked = carrierId == "no" && statusArr.includes(Const.JobStatus.created);
      this.needDriverChecked = driverId == "no" && statusArr.includes(Const.JobStatus.created);
      // let picId = this.getItemValue("picId");
      // this.needDispatcherChecked = picId == "no" && statusArr.includes(Const.JobStatus.created);
      if (!statusArr.includes(Const.JobStatus.completed)) {
        this.needPOPChecked = false;
      }
    }, 1); // phải kết thúc hàm onChange thì trong form mới có dữ liệu
  }


  protected beforeBindModel(model: any): any {
    let statusArr = model.status ?? [];
    for (let stt of statusArr) {
      this.selectedStatus[stt] = true;
    }
    this.needCarrierChecked = model.carrierId == "no" && statusArr.includes(Const.JobStatus.created);
    this.needDriverChecked = model.driverId == "no" && statusArr.includes(Const.JobStatus.created);
    this.needDispatcherChecked = model.picId == "no" && statusArr.includes(Const.JobStatus.created);
    this.needPOPChecked = model.isNeedPOD == true && statusArr.includes(Const.JobStatus.completed);
    return model;
  }

  protected setEnableFormGroup(enabled: boolean): void {
    // do nothing
  }

  public isFetchingDispatcher = false;
  private fetchListDispatchers(silent: boolean = false) {
    this.isFetchingDispatcher = true;
    this.api.getListDispatcherUsersFilter().subscribe(
      (resp) => {
        this.listDispatchers = resp?.data?.list_data ?? [];
        this.isFetchingDispatcher = false;
      },
      (err) => {
        if (silent) {
          // Nếu silent thì không bắn lỗi lên màn hình.
          Log.e(err);
        } else {
          this.showErr(err);
        }
        this.isFetchingDispatcher = false;
      }
    );
  }

  async onBtnExport() {
    this.isExporting = true;
    let query = await this.buildQuery();
    query['download'] = 1;
    if (!query['filter'] && !query['search']) {
      query['exportAll'] = 1;
    }
    this.api.postExport(`${Const.APIURI_JOBS}/export`, query).subscribe(
      (resp) => {
        ApiService.handleDownloadResponse(resp);
        this.isExporting = false;
      },
      (err) => {
        this.showErr(err);
        this.isExporting = false;
      }
    );
  }

  public countStatus() {
    let filter = {};
    // const paramQuery = this.buildQuery();
    if (Utils.parseQueryStringFromUrl(this.router.url).filter) {
      filter = JSON.parse(Utils.parseQueryStringFromUrl(this.router.url).filter??"{}");
    }
    this.api.POST(`${Const.APIURI_JOBS}/count_status`, filter).subscribe(
      (resp) => {
        this.countStatusData = resp.data;
        this.lastUpdated = resp.data?.lastUpdated;
        this.requestId = resp.data?.requestId;
      },
      (err) => {
        Log.e(err);
      }
    );
  }

  public onDispatcherAssigned(dispacher: any) {
    for (let item of this.listDispatchers) {
      if (item.id == dispacher.id) {
        // Đã có trong danh sách rồi, ko cần xử lý nữa
        return;
      }
    }
    // Chưa có trong danh sách thì thêm vào
    this.listDispatchers.push(dispacher);

    // Bên trên là update ngay để đáp ứng tính real-time, đảm bảo dispatcher mới sẽ có mặt trong danh sách fiter.
    // Sau đó thì âm thầm gọi lại API để lấy danh sách chuẩn, đảm bảo những dispatcher không cần thiết sẽ không nằm trong danh sách filter.
    this.fetchListDispatchers(true);
  }

  onBtnReassign() {
    DialogService.openFormDialog1(ReAssignDispatcherComponent, {
      nzComponentParams: {
        closeOnSuccess: true,
        updateSuccess: (resp) => {
          this.fetchListDispatchers();
          this.onRefresh();
        },
      },
      nzClassName: "re-assign-dispatcher-form",
    });
  }

  getTimezoneShort() {
    const localTimeZone = DateUtil.getLocalTimeZone();
    const localTimeZoneShort = DateUtil.timezoneStandardToUsShort(localTimeZone);
    return localTimeZoneShort || "";
  }

  get lastUpdatedTime() {
    if (!this.lastUpdated) return "N/A";
    return DateUtil.format(this.lastUpdated, Const.DATETIME_FORMAT_FROM_DB);
  }

  onBtnSearchNote() {
    DialogService.openDialog(SearchNoteDialog, {
      nzClassName: 'search-note-dialog'
    })
  }

  async setLocation(selectedLocation) {
    if (selectedLocation.warpId) { // location
      let url = `${Const.APIURI_WAREHOUSES}/${selectedLocation.id}`;
      const resp = await this.api.GET(url).toPromise().catch(err => {
        this.showErr(err);
      });
      if (resp) {
        var addr = resp?.data?.pickAddr;
        return {street: addr.street, city: addr.city, state: addr.state, zipcode: addr.zipcode, metadata: selectedLocation};
      }
    } else if (selectedLocation.formatedAddress) {// regions
      const terms = selectedLocation.raw.terms;
      let region = {street: '', city: '', state: '', metadata: selectedLocation};
      if (terms.length === 5 || terms.length === 4) {
        region.street = terms[1].value;
        region.city = terms[2].value;  
        region.state = terms[3].value; 
      } else if (terms.length === 3 || terms.length === 2) {
        region.city = terms[0].value;
        region.state = terms[1].value; 
      }
      Object.keys(region).forEach(key => {
        if(!region[key]) delete region[key];
      });
      return region;
    } else if (this.allStates.find(item => item.code === selectedLocation)) {// state
      return {state: selectedLocation.code};
    } else return selectedLocation;
  }

  // async createDataFilterLocation(json) {
  //   delete json['dropoffCountry'];
  //   delete json['pickupCountry'];
  //   if (Utils.isObjectNotEmpty(json['pickupLocation'])) 
  //     json['pickupLocation'] = await this.setLocation(json['pickupLocation']);
  //   if (Utils.isObjectNotEmpty(json['dropoffLocation'])) 
  //     json['dropoffLocation'] = await this.setLocation(json['dropoffLocation']);
  //   return json;
  // }
  applyFilterData(data) {
    let sort = Utils.parseQueryStringFromUrl(this.router.url).sort ?? null;
    let statusArr = null;
    let preCheckboxPOD = false;
    try {
      statusArr = JSON.parse(Utils.parseQueryStringFromUrl(this.router.url)?.filter)?.status;
      preCheckboxPOD = JSON.parse(Utils.parseQueryStringFromUrl(this.router.url)?.filter)?.isNeedPOD ?? false;
    } catch (e) {}
    const { needDispatcherChecked, needCarrierChecked, needDriverChecked, needPODChecked, isWalmartScheduledLessThanDuration, ...dataFormJson } = data;
    this.clearFilter();
    this.setFormValues({"status": statusArr});
    this.setFormValues({"sort": sort});
    this.setFormValues(dataFormJson);
    this.needDispatcherChecked = needDispatcherChecked;
    this.needCarrierChecked = needCarrierChecked;
    this.needDriverChecked = needDriverChecked;
    this.needPOPChecked = needPODChecked;
    this.isWalmartScheduledLessThanDuration = isWalmartScheduledLessThanDuration;
    if(needCarrierChecked == true) {
        this.selectedCarrier["Needed"] = true;
        this.selectedCarrier["Assigned"] = false;
    } else {
        this.selectedCarrier["Needed"] = false;
    }
    if(dataFormJson.carrierId == 'yes') {
        this.selectedCarrier["Needed"] = false;
        this.selectedCarrier["Assigned"] = true;
    } else {
        this.selectedCarrier["Assigned"] = false;
    }
    if(needPODChecked != preCheckboxPOD) {
      if(needPODChecked == true) {
        this.onCheckboxNeedPOD(true);
      } else {
        this.onCheckboxNeedPOD(false);
      }
    }
    this.loadData();
  }

  onSelectFilter(filterInfo: FilterInfo) {
    this.applyFilterData(filterInfo?.data?.filterData || {});
  }

}
