<div class="ghost-load-header">
    <div class="btn-close center-children clickable" (click)="onBtnClose()">
        <i nz-icon nzType="close" nzTheme="outline"></i>
    </div>
    <div class="page-title flex1">Clone From Route</div>
</div>
<div class="separator h"></div>

<div class="ghost-load-content">
    <div *ngIf="isLoading" class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
    <div *ngIf="!isLoading && !isCloningFromLoad" class="nodata"><i nz-icon nzType="search" nzTheme="outline"></i>No data</div>
    <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 16, lg: 16 }">
        <div nz-col class="gutter-row" nzXs="24">
            <form nz-form [formGroup]="formInput" class="form-detail">
                <div class="group no-padding">
                    
                    <ng-container *ngIf="isCloningFromLoad">
                        <div class="bottom20">
                            <div class="form-label-v2 bottom5 medium color-text">
                                Vehicle Type
                            </div>
                            <div style="width: 400px;">
                                <div vehicle-selector [value]="vehicleTypeValue" (valueChange)="onVehicleTypeChange($event)" [withOptions]="true"></div>
                            </div>
                        </div>
                        <div *ngFor="let groupKey of ['stops']" [formArrayName]="groupKey">
                            <form *ngFor="let item of getArrayControls(groupKey); let pickInfoIndex = index" nz-form [formGroupName]="pickInfoIndex">
                                <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 16, lg: 16 }" class="bottom10">
                                    <div nz-col nzSm="24" nzMd="15">
                                        <ng-container *ngIf="isFullAdrressSelected">
                                            <div>Stop {{ pickInfoIndex + 1 }} : {{  getStopAddress(pickInfoIndex) }}</div>
                                        </ng-container>
                                    </div>
                                    <div nz-col nzSm="24" nzMd="9">
                                        <div class="flex">
                                            <nz-form-item>
                                                <nz-form-control>
                                                    <!-- <nz-range-picker [formControlName]="'rangeDate'" [nzFormat]="'yyyy/MM/dd'"></nz-range-picker> -->
                                                    <nz-range-picker style="margin-right: 20px;" *ngIf="'range' as childKey"
                                                        [formControlName]="'rangeDate'"
                                                        [nzShowTime]="{ nzFormat: 'HH:mm', nzMinuteStep: 15 }"
                                                        nzFormat="MM-dd-yyyy HH:mm">
                                                    </nz-range-picker>
                                                </nz-form-control>
                                            </nz-form-item>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div>
                            <div>
                                <span class="f15 medium">Shipping Items</span>
                                <span class="left10 small-button f13" (click)="onAddNewItem('Pallet')">
                                    <i nz-icon nzType="plus" nzTheme="outline"></i>
                                    Pallets
                                </span>
                            </div>
                            <div style="max-width: 800px;">
                                <div *ngIf="items && items.length > 0 " nz-row [nzGutter]="{md:8, xs: 2}" >
                                    <div nz-col nzXs="1"></div>
                                    <div nz-col nzXs="3" nzSm="0" class="label">Qty<span class="label-mark-required"></span></div>
                                    <div nz-col nzXs="0" nzSm="3" class="label">Quantity<span class="label-mark-required"></span></div>
                                    <div nz-col nzXs="0" nzSM="4" class="label">Packaging</div>
                                    <div nz-col nzXs="3" nzSM="0" class="label">Pkg</div>
                                    <div nz-col nzXs="3" class="label">Width<span class="label-mark-required"></span></div>
                                    <div nz-col nzXs="3" class="label">Length<span class="label-mark-required"></span></div>
                                    <div nz-col nzXs="3" class="label">Height<span class="label-mark-required"></span></div>
                                    <div nz-col nzXs="3" class="label">Per Pallet Weight<span class="label-mark-required"></span></div>
                                    <div nz-col nzXs="3" class="label">Total Weight</div>
                                </div>
                                <div *ngFor="let item of items">
                                    <div item-clone-route [item]="item" (onDelete)="onDeleteItem($event)"></div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </form>
        </div>
    </div>
</div>
<div class="separator h"></div>
<div class="ghost-load-content">
    <div form-footer class="flex form-footer" [onProgress]="onProgress" [nzIconCancel]="null" [nzIconOK]="null"
        [canClickOK]="needUpdate && !onProgress" [canClickCancel]="!onProgress"
        labelOK="Clone" (onOK)="onBtnCreateLoad()" (onCancel)="onBtnClose()">
    </div>
</div>