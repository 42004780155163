<div class="group-content">
    <div class="label medium">Route ID <span class="label-mark-required"></span></div>
    <div class="flex">
        <nz-form-item>
            <nz-form-control>
                <input nz-input [(ngModel)]="searchKeyword" (keypress)="onInputKeyPress($event)">
            </nz-form-control>
        </nz-form-item>
        <button class="left20" nz-button nzType="primary" [nzLoading]="isLoading" [disabled]="needUpdate" (click)="onSearchRoute()">Search</button>
    </div>
</div>