<form #form [formGroup]="formInput" nz-form class="filter-form" [ngClass]="{filterform: 1}">
  <div class="form-inner-box">
    <div class="form-group">
      <ng-container *ngFor="let key of formInputKeys">
        <ng-container *ngIf="key == 'status'">
          <div class="form-item" [ngClass]="key" *ngFor="let item of displayStatuses; let i = index">
            <div class="clickable btn-status {{item}}" [ngClass]="{'selected': isStatusSelected(item)}"
              (click)="onBtnStatus(item)">
              {{getStatusLabel(item)}}
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
    <div class="line"></div>
    <div class="form-group" style="flex: 1;">
      <search-box #searchBox class="search-box" [placeHolder]="'Search'" [searchKeyword]="search"
        (doSearch)="doSearch($event)">
      </search-box>
    </div>
    <div class="flex item" style="flex: 1; justify-content: flex-end;">
      <div class="line"></div>
      <div class="form-group">
        <select-filter-info-component #selectFilterInfo screen="dispatch" (onFilterChange)="onSelectFilter($event)" (onEdit)="onBtnAllFilter($event)"
          [defaultFilters]="defaultFilterInfos"
        ></select-filter-info-component>
        <!-- <ng-container *ngFor="let key of formInputKeys" class="form-group">
          <ng-container *ngIf="key == 'carrierId'">
            <div class="form-item" [ngClass]="key" *ngFor="let item of displayCarrierStatuses; let i = index">
              <div class="clickable btn-status carrier" [ngClass]="{'selected': selectedCarrier[item]}"
                (click)="onBtnCarrierStatus(item)">
                {{getCarrierStatusLabel(item)}}
              </div>
            </div>
          </ng-container>
        </ng-container> -->
      </div>
      <div class="line"></div>
      <div class="form-group">
        <ng-container *ngFor="let key of formInputKeys">
          <ng-container *ngIf="key == 'sort'" class="btn-sort-container form-item">
            <div nz-button nz-dropdown nzTrigger="click" [nzDropdownMenu]="sortMenu" nzType="text"
              class="btn-filter-sort" #sortFilterDispatch nzDropdownClassName="dispatch-filter-sort" style="
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              position: relative;
            ">
              <div style="display: flex; margin: 0 16px; align-items: center;">
                <div class="icon sort_by_alpha"></div>
                <span>Sorting</span>
              </div>

              <p style="
                color: var(--dispatch-grey);
                font-size: 8px;
                width: 160px;
                position: absolute;
                top: 24px;
                text-align: center;
                font-weight: 600;
              ">
                {{getSortText()}}
              </p>
            </div>
          </ng-container>
        </ng-container>
        <div nz-button nzType="text" class="btn-filter-sort form-item" (click)="onBtnAllFilter()">
          <div style="display: flex; align-items: center; justify-content: center">
            <div class="icon filter"></div>
            <span style="width: max-content">{{contentBtnAllFilter()}}</span>
          </div>
        </div>
        <div nz-button style="display: flex; align-items: center; justify-content: center; margin-left: 4px;"
          nzType="text" nz-dropdown nzTrigger="click" nzPlacement="bottomRight" class="btn-filter-sort form-item"
          [nzDropdownMenu]="actionMenu"><span nz-icon nzType="plus" style="font-size: 18px;" nzTheme="outline"></span>
          Actions</div>
      </div>
    </div>
  </div>
</form>
<div *ngIf="lastUpdated">
  <div class="last-updated">Request ID: {{lastUpdatedTime}} [{{requestId}}]</div>
</div>
<nz-dropdown-menu #sortMenu>
  <ul nz-menu>
    <li nz-menu-item (click)="onBtnSort(item.value)" *ngFor="let item of sortTypes">{{item.name}}</li>
  </ul>
</nz-dropdown-menu>
<nz-dropdown-menu #actionMenu>
  <ul nz-menu class="action-menu">
    <li nz-menu-item>
      <button nz-button style=" border: none; background-color: inherit; box-shadow: none; text-align: start;"
        class="btn-status" (click)="onBtnReassign()">
        <i nz-icon nzType="user-switch" nzTheme="outline"></i>
        Reassign
      </button>
    </li>
    <li nz-menu-item>
      <button nz-button
        style=" border: none; background-color: inherit; width: 100%;  box-shadow: none; text-align: start;"
        class="btn-status" (click)="onBtnExport()" [nzLoading]="isExporting">
        <i nz-icon nzType="file-excel" nzTheme="outline"></i>
        Export
      </button>
    </li>
    <li nz-menu-item>
      <button nz-button
        style=" border: none; background-color: inherit; width: 100%;  box-shadow: none; text-align: start;"
        class="btn-status" (click)="onBtnSearchNote()">
        <i nz-icon nzType="search" nzTheme="outline"></i>
        Search By Note
      </button>
    </li>
  </ul>
</nz-dropdown-menu>
