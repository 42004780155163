import { NgModule } from "@angular/core";
import { WorkLogComponent } from ".";
import { CommonModule } from "@angular/common";
import { UiCommonModule } from "@app/admin/components/common/module";
import { NzTimelineModule } from 'ng-zorro-antd/timeline';
import { NzAlertModule } from 'ng-zorro-antd/alert';


@NgModule({
    imports: [CommonModule, UiCommonModule, NzTimelineModule, NzAlertModule],
    providers: [],
    declarations: [WorkLogComponent],
    exports: [WorkLogComponent]
})
export class WorkLogModule {}
