
<ng-container [ngTemplateOutlet]="noteForm" *ngIf="edittingId ==-1"
  [ngTemplateOutletContext]="{btnOk: 'Submit', onSubmit: onAdd, onCancel: close}"></ng-container>

<div *ngIf="isLoading" class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>

<div class="note-list" *ngIf="!isLoading">
  <div *ngIf="pinnedItems.length > 0" class="pinned-list">
    <div class="bottom5 flex">
      <div class="label">
        <span nz-icon nzType="pushpin" nzTheme="outline"></span>
        <span class="f13 left5">{{pinnedItems.length}} Pinned</span>
      </div>
    </div>
    <div class="group-content">
      <ng-container *ngFor="let item of pinnedItems" [ngTemplateOutlet]="noteItem"
        [ngTemplateOutletContext]="{item, edittingId}"></ng-container>
    </div>
  </div>
  <div class="group-date bottom20" *ngFor="let date of getDateItems()">
    <div class="date-label">{{date}}</div>
    <div class="group-content">
      <ng-container *ngFor="let item of items[date]" [ngTemplateOutlet]="noteItem"
        [ngTemplateOutletContext]="{item, edittingId}"></ng-container>
    </div>
  </div>
  <div *ngIf="carrierId">
    <note-list-carrier-bid [carrierId]="carrierId" [bidId]="bidId" [routeCode]="routeCode"></note-list-carrier-bid>
  </div>
</div>

<ng-template #noteForm let-onCancel="onCancel" let-onSubmit="onSubmit" let-edittingId="edittingId">
  <div class="create-form">
    <textarea nz-input class="input-note" placeholder="Add a note..." [(ngModel)]="newNoteMsg"
      [nzAutosize]="{ minRows: 2, maxRows: 6 }" [disabled]="isLoading"></textarea>
      <ng-container *ngIf="!edittingId">
        <form-input-upload-images #uploadImagesForm [visible]="!isLoading" (fileSelectedChange)="onFileImageSelectedChange($event)"></form-input-upload-images>
      </ng-container>
    <nz-space nzSize="small" class="note-button-group">
      <ng-container *ngIf="shouldShowBtnCancel" >
        <button *nzSpaceItem nz-button (click)="onCancel()">Cancel</button>
      </ng-container>
      <button *nzSpaceItem nz-button nzType="primary" (click)="onSubmit()" [disabled]="!shouldShowBtnAddNote">{{getLabelBtnAdd()}}</button>
    </nz-space>
  </div>
</ng-template>

<ng-template #noteItem let-item="item" let-edittingId="edittingId">
  <div class="note-list-item">
    <div class="item-header">
      <div class="poster">
        <ng-container *ngIf="isExfreight(item)">
          <nz-avatar nzIcon="user" nzSrc="assets/img/exfreight.png"></nz-avatar>
        </ng-container>
        <ng-container *ngIf="!isExfreight(item)">
          <nz-avatar [nzText]="getAuthorName(item)?.slice(0,1)" [ngStyle]="{ 'background-color': '#7265e6' }"></nz-avatar>
        </ng-container>
        <div class="info">
          <div class="name semibold">
            <a class="link" [ngClass]="{'deleted': item.insert?.by?.isDeleted}" [href]="getHyperLink(item.insert?.by)" target="_blank">{{getAuthorName(item, 'No name')}}</a>
            <span *ngIf="item.pin?.when" nz-icon nzType="pushpin" nzTheme="fill" class="left10" style="color: #FAAD14;"></span>
          </div>
          <div class="time">{{item.time}}</div>
          <div class="top" style="width: 100%; margin-top: 5px;">
            <div style="line-height: 22px;word-wrap: break-word;" *ngIf="item.content && edittingId !== item.id" [innerText]="item.content"></div>
            <div class="time" style="margin-top: 6px;" *ngIf="shouldShowUpdate(item)">Updated: {{ $formatDate(item.update.when) }} by {{ getAuthorName(item, 'No name') }}</div>
            <div *ngIf="item.attachedFiles.length > 0" class="list-images-upload">
              <div *ngFor="let image of item?.attachedFiles;let i = index">
                <img *ngIf="image" (click)="viewImageItem(image.url)" class="attached-image" [src]="image.url" alt="">
              </div>
            </div>
            <ng-container [ngTemplateOutlet]="noteForm" *ngIf="edittingId == item.id"
              [ngTemplateOutletContext]="{onSubmit: onSubmitEdit, onCancel: onCancelEdit, edittingId}"></ng-container>
          </div>
        </div>
      </div>
      <div class="menu-header">
        <i nz-icon nzType="more" nzTheme="outline" class="clickable" nz-popover [nzPopoverContent]="moreActions"></i>
        <ng-template #moreActions>
          <div class="more-actions-note">
            <div *ngIf="!item.pin?.when" class="action" (click)="onBtnPin(item)">Pin this note</div>
            <div *ngIf="item.pin?.when" class="action" (click)="onBtnUnPin(item)">Un-pin this note</div>
            <div class="action" (click)="onEdit(item)" *ngIf="canEditNote(item)">Edit message</div>
          </div>
        </ng-template>
        <!-- <i nz-icon nzType="edit" class="edit-btn" nzTheme="outline" (click)="onEdit(item)"></i> -->
      </div>
    </div>
    <div class="item-body top20">
      
    </div>
  </div>
</ng-template>
