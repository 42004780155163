import { NgModule } from "@angular/core";
import { SelectFilterInfoComponent } from "./select-filter-info";
import { CommonModule } from "@angular/common";
import { UiCommonModule } from "../common/module";
import { NzSelectModule } from "ng-zorro-antd/select";
import { NzPopoverModule } from "ng-zorro-antd/popover";
import { FormsModule } from "@angular/forms";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzButtonModule } from "ng-zorro-antd/button";
import { FilterInfoService } from "./filter-info.service";
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';

@NgModule({
  imports: [CommonModule, UiCommonModule, FormsModule, NzSelectModule, NzPopoverModule, NzIconModule, NzButtonModule, NzDropDownModule],
  providers: [FilterInfoService],
  declarations: [SelectFilterInfoComponent],
  exports: [SelectFilterInfoComponent],
})
export class SelectFilterInfoModule {}
