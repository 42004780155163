import { Shipment } from "@wearewarp/types/data-model";
import {Const, WarpId} from "@wearewarp/universal-libs";

export default class ShipmentEntity {
  private data: Shipment & { jobInfo?: any };

  init(shipment: Shipment) {
    this.data = shipment;
    return this;
  }

  getId() {
    return this.data.id;
  }

  getWarpId() {
    return this.data.warpId
  }

  getLastJobId() {
    return this.data.lastJobId
  }

  getJobCode() {
    return this.data?.jobInfo?.code || this.getLastJobId
  }

  getShipmentType() {
    return this.data.shipmentType
  }

  getPickInfo() {
    return this.data.deliveryInfos.find(d => d.type == Const.TaskType.PICKUP)
  }

  getDropInfo() {
    return this.data.deliveryInfos.find(d => d.type == Const.TaskType.DROPOFF)
  }

  getClient(){
    return this.data.metadata.client
  }

  getType() {
    return this.data.shipmentType
  }

  toJSON() {
    return {
      ...this.data
    }
  }

  getTags() {
    return this.data.tags
  }

  getVehilceType() {
    return this.data?.vehicleType || {}
  }

  getCode() {
    return this.data.code;
  }

  getCodeText(){
    return WarpId.showShipmentCode(this.data)
  }

  getOrderId() {
    return this.data.orderId;
  }
}
