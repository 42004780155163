
<div class="float-right">
  <div (click)="closeDialog()">
    <i class="f16" nz-icon nzType="close"></i>
  </div>
</div>
<div class="content">
  <div class="modal-title font-semibold f15 center-children">
    Add New Shipments
  </div>
  <div class="top15 center-children" style="color: red" *ngIf="!shouldShowAddShipments">{{ warningMessage }}</div>
  <div class="modal-content top15">
    <nz-table #tableShipmentsValidate [nzData]="listShipmentForTable" [nzShowPagination]="false">
      <thead>
        <tr>
          <th nzWidth="150px">
            WARP ID 
          </th>
          <th>
            Pickup Address & Time
          </th>
          <th>
            Dropoff Address & Time
          </th>
          <th nzWidth="100px">
            Shipment Type
          </th>
          <th nzWidth="80px">
            Validation
          </th>
          <th nzWidth="300px">
            Reason for failure
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let shipment of tableShipmentsValidate.data">
          <td>
            <div *ngIf="shipment.onHold" class="hold-badge">HOLD</div>
            <div *ngIf="shipment.onHold" class="top20"></div>
            <div>{{shipment?.warpId}}</div>
          </td>
          <td>
            <div *ngIf="shipment?.pickInfo">
              <b>{{shipment?.pickInfo?.locationName || "N/A"}}</b>
              <div>{{getAddressText(shipment?.pickInfo?.addr) || "N/A"}}</div>
              <div class="shipment-mode">{{getDeliveryInfoTime(shipment?.pickInfo) || "N/A"}}</div>
            </div>
          </td>
          <td>
            <div *ngIf="shipment?.dropInfo">
              <b>{{shipment?.dropInfo?.locationName || "N/A"}}</b>
              <div>{{getAddressText(shipment?.dropInfo?.addr) || "N/A"}}</div>
              <div class="shipment-mode">{{getDeliveryInfoTime(shipment?.dropInfo) || "N/A"}}</div>
            </div>
          </td>
          <td>{{shipment?.shipmentType}}</td>
          <td>
            <div class="center-children f20">
              <span *ngIf="shipment.validate" nz-icon [nzType]="'check-circle'" [nzTheme]="'twotone'" [nzTwotoneColor]="'#52c41a'"></span>
              <span *ngIf="!shipment.validate" nz-icon [nzType]="'close-circle'" [nzTheme]="'twotone'" [nzTwotoneColor]="'red'"></span>
            </div>
          </td>
          <td style="color: red;">
            {{shipment?.reason}}
            <a *ngIf="shipment?.routeId" target="_blank" style="font-size: 13px;" [routerLink]="[routeAdminDispatchList, shipment?.routeId]"
              nz-tooltip nzTooltipTitle="Go to dispatch">
              <code>{{shipment?.jobCode}}</code>
            </a>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
  <div class="top15 flex" style="justify-content: flex-end;">
    <div class="flex">Total: {{ countShipmentSelected() }} shipments selected
      (
      <div *ngIf="countShipments.valid > 0">Valid: {{countShipments.valid}}</div>
      <div *ngIf="countShipments.valid > 0 && countShipments.invalid > 0">,</div>
      <div *ngIf="countShipments.invalid > 0"> Invalid: {{countShipments.invalid}}</div>
      )
    </div>
</div>

  <div class="top15 flex" style="justify-content: flex-end;">
      <button [disabled]="!shouldShowAddShipments" nz-button nzType="primary" (click)="onBtnAddShipment()">Next</button>
  </div>
</div>
