import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { BasicRouteInfo } from '.';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NzButtonModule,
    NzIconModule,
  ],
  declarations: [
    BasicRouteInfo,
  ],
  exports: [
    BasicRouteInfo,
  ],
  providers: []
})
export class ModuleBasicRouteInfo {}