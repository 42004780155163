<div *ngIf="isLoading" class="nodata"><i nz-icon nzTheme="outline" nzType="loading"></i></div>

<div *ngIf="!isLoading">
  <div class="route-id">Route ID: <b>{{data.routeId}}</b></div>
  <!-- <div>Distance: {{data.distance}}</div> -->
  <div>Equipment: {{data.equipment}}</div>
  <div *ngIf="data.tempRanges">Temp: {{data.tempRanges}}</div>
  <div *ngIf="data.productType">Product Type: {{data.productType}}</div>
  <div *ngIf="data.classification">Shipment Classification: {{data.classification}}</div>
  <ul style="margin-top: 10px;">
    <li *ngFor="let stop of data.stops; let i = index;">
      <div>Stop {{i+1}}: {{stop.type}}</div>
      <div><i nz-icon nzType="environment" nzTheme="outline"></i> {{stop.address}}</div>
      <div><i nz-icon nzType="clock-circle" nzTheme="outline"></i> {{stop.time}}</div>
    </li>
  </ul>
</div>