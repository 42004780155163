import { Injector, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NzTableModule } from "ng-zorro-antd/table";
import { NzSelectModule } from "ng-zorro-antd/select";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzCollapseModule } from "ng-zorro-antd/collapse";
import { NzCheckboxModule } from "ng-zorro-antd/checkbox";
import { NzRadioModule } from "ng-zorro-antd/radio";
import { NzDividerModule } from "ng-zorro-antd/divider";
import { NzDatePickerModule } from "ng-zorro-antd/date-picker";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { NzTypographyModule } from "ng-zorro-antd/typography";
import { NzDrawerModule } from "ng-zorro-antd/drawer";
import { NzPopoverModule } from "ng-zorro-antd/popover";
import { NzModalModule } from 'ng-zorro-antd/modal';
import { enUS } from 'date-fns/locale';
import { DeliveryUpdateModule } from "../components/delivery-update/module";
import { MapModule } from "../components/map/module";

// import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { SearchBoxModule } from '@libs/search-box/search-box.module';
import { DetailModule } from '../base/detail.module';
import { SelectBySearchingModule } from '@libs/select-client/module';
import { FormInputCostModule } from '../base/form-input-cost/module';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { SharedModule } from "../shared/shared.module";
import { CustomIconModule } from "../components/icon/icon.module";
import { FormInputUploadImagesModule } from "../base/form-input-upload-images/module";
import { NzImageModule } from "ng-zorro-antd/image";
import { DrawerModule } from "@app/drawers/drawer.module";
import { DispatchListHeaderFilterV3 } from './components/list-header-filter-v3';
import { DispatchListHeaderFilterV4 } from './components/list-header-filter-v4';
import { FilterModal } from './components/list-header-filter-v4/filter-modal';
import { DispatchList } from "./list";
import { DispatchDetail } from "./detail";
import { DispatchRouteHeader } from "./components/route-header";
import { DispatchRouteDetail } from "./components/route-detail";
import { DispatchSidebarMenu } from './components/sidebar-menu';
import { DispatchSidebarMap } from "./components/sidebar-map";
import { ShipRecModule } from "../base/shiprec/module";
import { JobLiveIconComponent } from "./components/job-live-icon";
import { DispatchCarrierAndDriver } from "./components/carrier-and-driver";
import { DispatchAssignCarrierAndDriver } from "./components/assign-carrier";
import { SendDriverSMS } from "./components/assign-carrier/send-driver-sms";
import { AddNewDriver } from "./components/assign-carrier/add-new-driver";
import { ChangeDriverV2 } from "./components/assign-carrier/change-driver";
import { DispatchDetailScreen } from "./detail-screen";
import { ModalHelper, UpdateTaskStatusModule, ModuleUpdateEta, ModuleUpdateEquipments, ModuleFilterStates, ModuleFormCarrierCost } from '@wearewarp/ng-antd'
import { AddTrailerNumber } from "./components/carrier-and-driver/add-trailer-number/add-trailer-number";
import { DriverDownTime } from "./components/carrier-and-driver/driver-down-time";
import { DispatchSales } from "./components/sales";
import { RoutePOD } from "./components/pod";
import { DispatchDocuments } from "./components/documents";
import { DocumentCustomerComponent } from "./components/documents/document-customer";
import { UploadDocumentCustomer } from "./components/documents/document-customer/upload-document";
import { EmailBolComponent } from "./components/documents/email-bol/email-bol.component";
import { EmailLoadTenderComponent } from "./components/documents/email-load-tender/email-load-tender.component";
import { AddDriverLocation } from "./components/driver-location";
import { NoteList } from "./components/notes";
import { NoteListCarrierBid } from "./components/notes/notes-from-bid";
import { HistoryList } from "./components/histories";
import { HistoryDetail } from "./components/histories/history-detail";
import { DriverMessage } from "./components/driver-message";
// import { DispatchMap } from "./components/dispatch-map";
import { AssignPic } from "./components/assign-pic";
import { ScrollingModule } from '@angular/cdk/scrolling';
import { RouteModule } from "../route/module";
import { StopSettings } from "./components/stop-settings";
import { UpdateArrivedTime } from "./components/update-actual-time";
import { AddReasonCode } from "./components/add-reason-code";
import { RouteReturn } from "./components/init-return/task-return";
import { ReturnLocation } from "./components/init-return/return-location";
import { FormDeliveryInfoModule } from "../base/delivery-info/module";
import { AddCustomTracking } from "./components/carrier-and-driver/add-custom-tracking";
// import { DisplayCarrierCost } from "./components/carrier-and-driver/display-carrier-cost";
import { DispatchRoutingModule } from "./dispatch.route";
import { ApiService } from "@services/api.service";
import { NzI18nService } from "ng-zorro-antd/i18n";
import { getInjector, setInjector } from "@services/injector";
import { ReAssignDispatcherComponent } from "./components/re-assign-dispatcher";
import { AssignCarrierWithCost } from "./components/assign-carrier-with-cost";
import { AddReferenceNumber } from "./components/carrier-and-driver/add-reference-number";
import { AddCarrierSaleRep } from "./components/sales/add-carrier-sale-rep";
import { UiCommonModule } from "../components/common/module";
import { EventsModule } from "../components/events/module";
// import { DispatchRouteMap } from "./components/dispatch-route-map";
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { DispatchDetailEmpty } from '@app/admin/dispatch/detail-empty';
import { DispatchCustomerInfo } from "./components/customer-info";
import { ModuleDisplayCarrierCost } from "./components/carrier-and-driver/display-carrier-cost/module";
import { CreateByUserModule } from "../components/create-by-user/module";
import { NgxViewerModule } from 'ngx-viewer';
import { ModuleDriverAppStatus } from "./dispatch-live-tracking/driver-app-status/module";
import { ModuleCarrierCostIssue } from "./components/carrier-and-driver/cost-issue/module";
import { RouteUpdatePodTasks } from "./components/update-pod-tasks";
import { DispatchRevenueInfo } from "./components/revenue-info";
import { ModuleNotes } from "../components/notes/module";
import { DispatchOrderIssue } from "./components/order-issue-drawer";
import { DispatchSendMailToCSR } from "./components/send-mail-csr";
import { PodIssuePopup } from "./components/pod-isuse-popup";
import { DispatchRouteMapModule } from "./components/dispatch-route-map/module";
import { ConfirmCancelShipmentPopup } from "./components/confirm-cancel-shipment-popup";
import { CountdownTimerModule } from "./components/countdownTimer/module";
import { ListJobItemModule } from "./components/list-job-item/module";
import { ModuleCarrierCostIssueConfirm } from "./components/carrier-and-driver/cost-issue-post-confirm/module";
import { ChangeSecondaryDriver } from "./components/assign-carrier/change-secondary-driver";
import { ModuleRating } from "../components/rating/module";
import { SearchNoteDialog } from "./components/search-note";
import { ModuleWhenBy } from "../components/whenby/module";
import { ModuleBasicRouteInfo } from "./components/basic-route-info/module";
import { WorkLogModule } from "./components/work-log/module";
import { ModuleCloneRoute } from "./components/clone-route/module";
import { SelectFilterInfoModule } from "../components/filter-info/select-filter-info.module";
import { AddItemQuick } from "./components/add-item-quick";
import { UpdateEquipmentUnassignCarrier } from "./components/update-equipment-unassign-carrier";
import { CreateTONURouteDialog } from "./components/create-tonu-route-dialog";

@NgModule({
  imports: [
    CommonModule,
    DispatchRoutingModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NzTableModule,
    NzPaginationModule,
    NzSelectModule,
    NzButtonModule,
    NzInputModule,
    NzIconModule,
    NzFormModule,
    NzCollapseModule,
    NzCheckboxModule,
    NzRadioModule,
    NzDividerModule,
    NzDatePickerModule,
    NzToolTipModule,
    NzTypographyModule,
    NzDrawerModule,
    NzSpaceModule,
    NzAvatarModule,
    NzImageModule,
    NzDropDownModule,
    NzPopoverModule,
    NzSkeletonModule,
    NzModalModule,
    SearchBoxModule,
    ScrollingModule,
    FormInputUploadImagesModule,
    DetailModule,
    SelectBySearchingModule,
    FormInputCostModule,
    SharedModule,
    NzBadgeModule,
    DrawerModule,
    EventsModule,
    CustomIconModule,
    UpdateTaskStatusModule,
    ModuleUpdateEta,
    ModuleUpdateEquipments,
    ShipRecModule,
    RouteModule,
    FormDeliveryInfoModule,
    DeliveryUpdateModule,
    ModuleFilterStates,
    UiCommonModule,
    MapModule,
    NzEmptyModule,
    ModuleFormCarrierCost,
    ModuleDisplayCarrierCost,
    CreateByUserModule,
    NgxViewerModule,
    ModuleDriverAppStatus,
    ModuleCarrierCostIssue,
    ModuleCarrierCostIssueConfirm,
    ModuleNotes,
    DispatchRouteMapModule,
    CountdownTimerModule,
    ModuleRating,
    ModuleBasicRouteInfo,
    ListJobItemModule,
    WorkLogModule,
    ModuleWhenBy,
    ListJobItemModule,
    ModuleCloneRoute,
    SelectFilterInfoModule,
  ],
  declarations: [
    DispatchList,
    DispatchDetailScreen,
    DispatchDetail,
    DispatchRouteHeader,
    DispatchRouteDetail,
    DispatchListHeaderFilterV3,
    DispatchSidebarMenu,
    DispatchSidebarMap,
    DispatchCarrierAndDriver,
    DispatchAssignCarrierAndDriver,
    SendDriverSMS,
    AddNewDriver,
    ChangeDriverV2,
    ChangeSecondaryDriver,
    AddTrailerNumber,
    DriverDownTime,
    AddDriverLocation,
    // DisplayCarrierCost,
    RoutePOD,
    RouteReturn,
    ReturnLocation,
    DispatchSales,
    DispatchDocuments,
    DocumentCustomerComponent,
    DispatchRevenueInfo,
    UploadDocumentCustomer,
    EmailBolComponent,
    EmailLoadTenderComponent,
    StopSettings,
    DispatchListHeaderFilterV3,
    ReAssignDispatcherComponent,
    UpdateArrivedTime,
    AddReasonCode,
    NoteList, HistoryList,
    NoteListCarrierBid,
    HistoryDetail,
    DriverMessage,
    // DispatchMap,
    AssignPic,
    AssignCarrierWithCost,
    AddCustomTracking,
    AddReferenceNumber,
    DispatchListHeaderFilterV4,
    FilterModal,
    AddCarrierSaleRep,
    // DispatchRouteMap,
    DispatchDetailEmpty,
    DispatchCustomerInfo,
    RouteUpdatePodTasks,
    DispatchOrderIssue,
    DispatchSendMailToCSR,
    PodIssuePopup,
    ConfirmCancelShipmentPopup,
    SearchNoteDialog,
    AddItemQuick,
    UpdateEquipmentUnassignCarrier,
    CreateTONURouteDialog,
  ],
  exports: [
    DispatchRouteHeader,
    DispatchDetail,
    AssignCarrierWithCost,
    DispatchListHeaderFilterV3,
    DispatchListHeaderFilterV4,
    DispatchRouteDetail,
    RouteUpdatePodTasks,
    NoteList, HistoryList,
    CreateTONURouteDialog,
    NoteListCarrierBid,
  ],
  providers: [
    // DispatchService,
    ModalHelper,
    ApiService
  ]
})
export class DispatchModule {
  // constructor(injector: Injector, private i18n: NzI18nService) {
  //   setInjector(injector);

  //   this.i18n.setDateLocale(enUS);  // necessary for manually entering value in nz-date-picker
  // }
}
