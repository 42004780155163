import { Component, ViewChild } from "@angular/core";
import { BaseList } from "../../base/list";
import { ActivatedRoute } from '@angular/router';
import { DispatchService } from "../dispatchService";
import { Const } from "@const/Const";
import { Subscription } from "rxjs";
import { InternalMessageService } from "@services/internal-message.service";
import { DispatchListHeaderFilterV4 } from "../components/list-header-filter-v4";
import { Utils } from '@services/utils';
import { DateUtil } from '@services/date-utils';

@Component({
  selector: 'dispatch-list',
  templateUrl: './index.html',
  styleUrls: [
    './index.scss',
  ]
})
export class DispatchList extends BaseList {
  public isScrollTop = false;
  public isFirstLoadDetail = true;
  public selectedItem = false;

  @ViewChild('listHeader') listHeader: DispatchListHeaderFilterV4;

  private messageSubscription: Subscription;

  constructor(
    public activatedRoute: ActivatedRoute,
    private dispatchService: DispatchService,
    private messageService: InternalMessageService,
  ) {
    super();
    this.messageSubscription = this.messageService.getMessage().subscribe((message) => {
      // xử lý chuyển tab my loads / all loads trong màn hình dispatch
      let filterObject = this.queryParams?.filter ? JSON.parse(this.queryParams.filter) : {};
      let currentTab = this.queryParams?.tab;
      const newTab = message;
      if (filterObject?.trackingTask) {
        delete filterObject.trackingTask;
      }
      if (newTab == Const.DispatchTab.my_load) {
        let newFilterObject = { ...filterObject, picId: this.authUser.id };
        let newQuery = { filter: JSON.stringify(newFilterObject), tab: newTab };
        let newParams = Object.assign(Utils.cloneObject(this.queryParams || {}), newQuery);
        this.router.navigate([this.routeAdminDispatchList], { queryParams: newParams });
      } else if (newTab == Const.DispatchTab.all_load) {
        if (currentTab == Const.DispatchTab.my_load) {
          if (filterObject.picId) {
            delete filterObject.picId;
            this.listHeader.setItemValue('picId', null);
          }
        }
        if (currentTab == Const.DispatchTab.tracking_task) {
          delete filterObject?.archivedTrackingTask;
        }
        this.routeWithQueryUrl({ filter: JSON.stringify(filterObject), tab: newTab });
      } else if (newTab == Const.DispatchTab.tracking_task) {
        if (currentTab == Const.DispatchTab.my_load) {
          if (filterObject.picId) {
            delete filterObject.picId;
            this.listHeader.setItemValue('picId', null);
          }
        }
        let newFilterObject = { ...filterObject, trackingTask: Const.trackingTaskStatus.incomplete }
        if (!Object.keys(filterObject).includes('archivedTrackingTask')) {
          newFilterObject = {...newFilterObject, archivedTrackingTask: false}
        }
        this.routeWithQueryUrl({ filter: JSON.stringify(newFilterObject), tab: newTab });
      }
      this.handlePinnedNotesChange(message);
      this.handleDriverMessagesChange(message);
    });
  }

  ngOnDestroy() {
    this.messageSubscription.unsubscribe();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.autoShrinkSideBar();
    this.dispatchService.routeData$.subscribe(() => {
      const route = this.dispatchService.getRoute()
      if (this.listData.length && route) {
        let index = this.listData.findIndex(item => item.id == route.getId());
        if (index >= 0) {
          let dispatch = this.listData[index];
          dispatch = {
            ...dispatch,
            ...route?.toJSON(),
          };
          this.listData[index] = dispatch;
        }
        //load lại task tracking
        const jobIds = this.listData?.map(item => item.id) ?? [];
        this.fetchJobTrackingTasks([route.getId()])
      }
    })
  }

  onRouterActivate(compRef) {
  }

  onRouterDeActivate(compRef) {
  }

  public get queryParams() {
    return super.queryParams
  }

  protected handleNavigationEnd(url: string, prevQueryParam: any): void {
    if (prevQueryParam?.filter) {
      this.isFirstLoadDetail = false;
    } else {
      this.isFirstLoadDetail = true;
    }
    this.selectedItem = this.activatedRoute?.firstChild?.snapshot?.params?.['id'];
    if (!this.queryParams.filter && !this.queryParams.search) {
      // set mặc định mở All Loads nếu click vào Dispatch trên Menu
      if (!this.selectedItem) {
        let filterObj = { ...DispatchListHeaderFilterV4.defaultFilter }
        let filter = JSON.stringify(filterObj)
        return this.routeWithQueryUrl({ filter, tab: Const.DispatchTab.all_load });
      } else {
        let filter = JSON.stringify(DispatchListHeaderFilterV4.defaultFilter);
        return this.routeWithQueryUrl({ filter });
      }
    }
    //nếu đổi filter thì reset lại list job
    if (JSON.stringify(prevQueryParam?.filter) != JSON.stringify(this.queryParams.filter)) {
      this.paginationData.reset()
    }
    super.handleNavigationEnd(url, prevQueryParam);
  }

  protected getApiUrl(): string {
    return Const.APIURI_JOBS + '/list/listForDispatchScreen';
  }

  public getDataMore() {
    if (this.isLoading) return
    if (this.listData?.length == this.totalCount) return false;
    let apiUrl = this.getApiUrl();
    if (!apiUrl) {
      return;
    }
    let params = this.prepareParamGetList();
    params.skip = this.listData.length;
    let qs = new URLSearchParams(params).toString();
    if (apiUrl.indexOf('?') === -1) {
      apiUrl += '?';
    } else {
      apiUrl += '&';
    }
    apiUrl += qs;
    this.getDataByUrlLoadMore(this.api.buildUrl(apiUrl));
  }
  protected getDataByUrlLoadMore(url: string) {
    this.isLoading = true;
    this.subApi?.unsubscribe();
    this.subApi = this.api.GET(url).subscribe(
      resp => {
        this.paginationData.append(resp.data);
        this.onGetDataSucceeded(resp);
        this.isLoading = false
      }, err => {
        this.showErr(err);
        this.isLoading = false
      }
    );
  }

  protected onGetDataSucceeded(resp) {
    if (this.listData.length <= this.limit) {
      this.isScrollTop = true;
      this.listHeader.countStatus();
    } else {
      this.isScrollTop = false;
    }
    this.autoSelectDetail();
    const jobIds = resp.data.list_data?.map(item => item.id) ?? [];
    if (jobIds.length > 0) {
      this.fetchNotes(jobIds);
      this.fetchDriverMessages(jobIds);
      this.fetchJobTrackingTasks(jobIds);
    }
  }

  protected autoSelectDetail() {
    let url = this.router.url.split('?')[0];
    console.log(url);
    let prefix = `${this.routeAdminDispatchList}/`;
    console.log(this.isScrollTop)
    if (url.startsWith(prefix) && url.length > prefix.length) {
      // already has detail page
      if (!this.isFirstLoadDetail && this.listData.length && this.isScrollTop) {
        // the first load dispatch detail don't select first item as defautl
        this.router.navigate([this.routeAdminDispatchList, this.listData?.[0]?.id], { queryParams: this.queryParams });
      }
    } else {
      // select first item as default
      if (this.listData.length > 0 && this.pageIndex == 1) {
        this.router.navigate([this.routeAdminDispatchList, this.listData?.[0]?.id], { queryParams: this.queryParams });
      }
      else if (this.listData.length == 0 && this.pageIndex == 1) {
        // no data - show empty page
        this.router.navigate([this.routeAdminDispatchList, "empty"], { queryParams: this.queryParams });
      }
    }
  }

  onBtnReassign() {
    this.listHeader.onBtnReassign();
  }

  onBtnExport() {
    this.listHeader.onBtnExport();
  }

  /**
   * Process Pinned note of Job
   */
  notesOfJobs = {}
  fetchNotes(jobIds: any[]) {
    const messageType = "note";
    const apiUrl = `${Const.APIURI_CONVERSATIONS}?${jobIds.map(id => `subjectIds[]=${id}`).join("&")}&subjectType=job&type=${messageType}&onlyPinned=true`;
    this.api.GET(apiUrl).subscribe(
      (resp) => {
        const listData = resp?.data?.list_data || [];
        jobIds.forEach(jobId => {
          this.notesOfJobs[jobId] = this.getPinnedNotesForJob(jobId, listData);
        });
      },
      (err) => { }
    );
  }
  getPinnedNotesForJob(jobId, listData) {
    let pinnedNotesOfJob = listData.filter(note => note.subjectId == jobId);
    // sorting pinned note by pin time
    pinnedNotesOfJob = pinnedNotesOfJob.sort(function (a, b) {
      if (!a.pinned?.when || !b.pinned?.when) return 0;
      let aDate = new Date(a.pinned?.when);
      let bDate = new Date(b.pinned?.when);
      return aDate.getTime() < bDate.getTime() ? 1 : (aDate.getTime() > bDate.getTime() ? -1 : 0)
    });
    // transform some data for using in FE
    pinnedNotesOfJob.forEach(note => {
      note?.imageUploadFilesArr?.forEach(image => { image.imgUrl = this.attachedFileUrl(image) });
      note['time'] = DateUtil.dateToString(note.insert?.when, "MM/DD/YY hh:mm A");
    });
    return pinnedNotesOfJob;
  }
  handlePinnedNotesChange(message) {
    if (message.key != "note_change" || !message.subjectId) return;
    let jobId = message.subjectId;
    this.notesOfJobs[jobId] = this.getPinnedNotesForJob(jobId, message.data);
  }

  /**
   * Process Driver messages of Job
   */
  driverMessagesOfJobs = {}
  fetchDriverMessages(jobIds: any[]) {
    const messageType = "driver_message";
    const apiUrl = `${Const.APIURI_CONVERSATIONS}?${jobIds.map(id => `subjectIds[]=${id}`).join("&")}&subjectType=job&type=${messageType}`;
    this.api.GET(apiUrl).subscribe(
      (resp) => {
        const listData = resp?.data?.list_data || [];
        jobIds.forEach(jobId => {
          this.driverMessagesOfJobs[jobId] = this.getDriverMessagesForJob(jobId, listData);
        });
      },
      (err) => { }
    );
  }
  getDriverMessagesForJob(jobId, listData) {
    let messages = listData.filter(item => item.subjectId == jobId);
    let hasResponseFromDriver = messages.filter(item => item.driver).length > 0;
    if (hasResponseFromDriver) {
      // transform some data for using in FE
      messages.forEach(message => {
        message['time'] = DateUtil.dateToString(message.insert?.when, "MM/DD/YY hh:mm A");
      });
      return { hasResponseFromDriver, messages }
    } else return { hasResponseFromDriver }
  }
  handleDriverMessagesChange(message) {
    if (message.key != "driver_message_change" || !message.subjectId) return;
    let jobId = message.subjectId;
    this.driverMessagesOfJobs[jobId] = this.getDriverMessagesForJob(jobId, message.data);
  }

  fetchJobTrackingTasks(jobIds: any[]) {
    let condition = {
      jobIds: jobIds
    }
    let params = {
      filter: JSON.stringify(condition)
    }
    let qs = new URLSearchParams(params).toString();
    const apiUrl = `${Const.APIURI_JOB_TRACKING_TASKS}/get-for-list-jobs?${qs}`;
    this.api.GET(apiUrl).subscribe(
      (resp) => {
        const listTrackingTasks = resp?.data?.list_data || [];
        this.addTrackingTasksForJobs(jobIds, listTrackingTasks);
      },
      (err) => { }
    );
  }

  addTrackingTasksForJobs(jobIds, listTrackingTasks) {
    let jobTrackingTasks: any = {};
    listTrackingTasks.forEach(item => {
      if (item.jobId) {
        if (jobTrackingTasks[item.jobId]) {
          jobTrackingTasks[item.jobId].push(item);
        } else {
          jobTrackingTasks[item.jobId] = [item];
        }
      }
    });
    for(let jobId of jobIds) {
      const index = this.listData.findIndex(item => item.id == jobId);
      if(index == -1) continue;
      this.listData[index] = {
        ...this.listData[index],
        listTrackingTasks: jobTrackingTasks[jobId] || []
      }
    }
  }
}
