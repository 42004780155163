import { BaseComponent } from "@abstract/BaseComponent";
import { Component } from "@angular/core";
import { Const } from "@const/Const";
import { DispatchService } from "../../dispatchService";
import { ActivatedRoute } from "@angular/router";
import RouteEntity from "../../entity/RouteEntity";
import { InputHelper } from "@services/input-helper";
import { ModalHelper } from "@wearewarp/ng-antd";
import { DispatchOrderIssue } from "../order-issue-drawer";
import { Template, User } from "@wearewarp/types/data-model";
import { DispatchSendMailToCSR } from "../send-mail-csr";
import { environment } from "@env/environment";
import { Utils } from "@services/utils";
import WarpUtil from "@services/warp.util";

@Component({
  selector: "[dispatch-revenue]",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})
export class DispatchRevenueInfo extends BaseComponent {
  data: any[] = [];
  template: Template;
  public loading: boolean = true;

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected dispatchService: DispatchService,
    private modalHelper: ModalHelper
  ) {
    super();
  }

  route: RouteEntity;
  ngOnInit() {
    super.ngOnInit();
    this.subscription.add(
      this.dispatchService.routeData$.subscribe(() => {
        this.route = this.dispatchService.getRoute();
        this.fetchTemplateEmail();
        this.fetchRevenues();
      })
    );
  }

  fetchRevenues() {
    this.loading = true;
    const url = `${Const.APIV2(Const.APIURI_JOBS)}/${this.route.getId()}/revenues`;
    this.api.GET(url).subscribe((res: any) => {
      this.data = this.processRevenue(res.data?.list_data);
      this.loading = false;
    });
  }

  fetchTemplateEmail() {
    const url = `${Const.APIV2(Const.APIURI_TEMPLATES)}/get/${Const.TemplateCodes.revenue_issues}`;
    this.api.GET(url).subscribe((res: any) => {
      this.template = res.data;
    });
  }

  processRevenue(shipments: any) {
    return shipments.map((shipment: any) => {
      return {
        ...shipment,
        customerRate: InputHelper.formatMoney2(shipment.cost?.grandTotal || 0),
        shipmentCode: WarpUtil.getShipmentCode(shipment),
        clientSalesRep: this.getFullName(shipment.metadata?.clientSalesRep) || '',
        saleRep: this.getFullName(shipment.metadata?.saleRep) || '',
        order: {
          ...shipment.order,
          orderCode: WarpUtil.getOrderCode(shipment?.order),
          customerRate: InputHelper.formatMoney2(shipment.order?.metadata?.customerRate || 0),
          totalShipmentsCost: InputHelper.formatMoney2(shipment.order?.metadata?.totalShipmentsCost || 0),
        }
      }
    })
  }
  onOpenIssue(order) {
    this.drawerService.create({
      nzTitle: 'Order Issue',
      nzContent: DispatchOrderIssue,
      nzWidth: 500,
      nzContentParams: {
        orderId: order.id
      }
    });
  }

  private makeContent(content, data = {}) {
    return content.replace(/{{(.*?)}}/g, (match, variable) => {
      const value = variable.split('.').reduce((obj, key) => obj[key], data);
      
      return value !== undefined ? value : '';
    });
  }

  async onOpenSendMail(shipment) {
    const customerSalesRep = shipment.metadata?.clientSalesRep;
    const carrierSalesRep = this.route.getCarrierSalesRep();
    const salesRep = shipment.metadata?.saleRep;
    const userData = await Promise.allSettled([
      this.api.GET(`${Const.APIURI_USERS}/${customerSalesRep?.id}`).toPromise(),
      this.api.GET(`${Const.APIURI_USERS}/${carrierSalesRep?.id}`).toPromise(),
      this.api.GET(`${Const.APIURI_USERS}/${salesRep?.id}`).toPromise()
    ])
    const users :User[] = userData.filter(user => user.status === 'fulfilled').map((user: any) => user.value.data);
    const emails = Utils.uniqElementsArray(users.map((user: User) => user.email)).filter(email => email);
    let url = `${location.origin}/dashboard/orders/${shipment.order?.id}`;
    let orderWarpId = shipment?.order?.orderCode;
    this.modalHelper.open(DispatchSendMailToCSR, {
      nzTitle: 'Send Mail To Client Sales Rep',
      nzWidth: 800,
      nzComponentParams: {
        recipients: [...emails, "billing@wearewarp.com"],
        orderId: shipment.order?.id,
        subject: this.template?.description ? this.makeContent(this.template?.description, { orderWarpId }) : `[WARP] Need to update revenue for order: ${orderWarpId}`,
        content: this.template?.description ? this.makeContent(this.template?.description, { orderWarpId, url }) : `Hi Team,\n\nWe need to update the revenue for order ${orderWarpId}.\nPlease click the link below and help to provide the correct revenue for this order.\n${url}\n\nThank you,\nWARP Finance Team`
      },
      nzFooter: null
    });

  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  getAllShipmentCodes(isNew = false) {
    let codes = this.data.map(it => it?.warpId);
    if(isNew) {
      codes = this.data.filter(it => it?.code).map(it => it?.code.replace('S-', ''));
    }
    return codes.join(', ')
  }

  getAllShipmentCodeAndWarpId() {
    let arr = this.data.map(item => {
      const hasCode = !!item?.code;
      const hasWarpId = !!item?.warpId;
      if (hasCode && hasWarpId) {
        return `${item.code.replace('S-', '')} ( ${item.warpId} )`;
      } else if (hasCode && !hasWarpId) {
        return item.code.replace('S-', '');
      } else if (!hasCode && hasWarpId) {
        return item.warpId;
      }
      return '';
    });
    return arr.join(', ');
  }

  hasShipmentCodes(): boolean {
    const codes = this.data.filter(it => it?.code);
    return !!codes.length
  }

  hasShipmentCode(data): boolean {
    return !!data?.code;
  }

  getShipmentCode(data, isNew = false) {
    if(!isNew) return data?.warpId;
    return (data?.code || '').replace('S-', '');
  }
}
